import React from 'react'
import { useEffect } from 'react';
import { getActiveUserApi, getActiveUserApiWthSearch } from '../../../APIs/AdminAll_api';
import { useState } from 'react';
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap';
import Pagination from '../Pagination';
import StatusChange from './StatusChange';
import CustomModal from '../../../Configs/CustomModal/CustomModal';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../Configs/Routes';

const ActiveUser = () => {
  const [activeList, setActiveList] = useState([])
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false)
  const [userData, setUserData] = useState({})
  const [totalRecord, setTotalRecord] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate()
  const limit = 10

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const getUser = async () => {
    setLoading(true);
    const response = await getActiveUserApi(currentPage, limit);
    if (response?.status === 200) {
      setActiveList(response.data?.data)
      setTotalRecord(response.data.totalRecord);
      setLoading(false)
    } else {
      setLoading(false)
    }
  };

  useEffect(() => {
    getUser();
  }, [currentPage]);

  const handleBan = async (item) => {
    setModalOpen(true);
    setUserData(item)
  }
  const searchApi = async (e) => {
    setLoading(true);
    try {
      const res = await getActiveUserApiWthSearch(search, currentPage, limit);
      if (res.status === 200) {
        setLoading(false);
        setTotalRecord(res?.data.totalRecord);
        setActiveList(res?.data?.data)
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (search) {
      searchApi();
    }
  }, [currentPage])

  const handleSearch = (e) => {
    e.preventDefault();
    if (search) {
      searchApi();
    }
  };

  useEffect(() => {
    if (!search) {
      getUser();
    }
  }, [currentPage, search]);


  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Active User</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content align-items-center">
          <div className="dashPageSearch me-2">
            <Form className="d-flex flex-wrap" onSubmit={handleSearch}>
              <Form.Group controlId="userSearch">
                <Form.Control
                  type="search"
                  name="search"
                  placeholder="Search..."
                  value={search}
                  required
                  onChange={(e) => { setSearch(e.target?.value) }}
                />
              </Form.Group>
              <Button type="submit" className="mx-2 search-btn">
                <i className="fa-solid fa-magnifying-glass"></i>
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <Row className="row-sm">
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive border-top userlist-table">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3 usersTable"
                >
                  <thead className="mb-3">
                    <tr className="mb-3 head-color">
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Phone No.</th>
                      <th>Name</th>
                      <th>Joined At</th>
                      <th className='text-center'>Status</th>
                      <th className='text-end '>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!loading && activeList.map((item, index) => {
                      let date = new Date(item.createdAt)
                      let createdDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                      let createdTime = date.getHours() + ':' + date.getMinutes();
                      return (
                        <tr key={index}>
                          <td>{item?.username || "-"}</td>
                          <td>{item?.email || "-"}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.first_name || "-"}</td>
                          <td>{createdDate} {createdTime} {date.getHours() > 12 ? 'PM' : 'AM'} <br />
                            {moment(date).fromNow()}</td>
                          <td className='text-center'>{item?.status ? "Inactive" : "Active"}</td>
                          <td className='text-end'>
                            <Button onClick={() => handleBan(item)} className='detailsBtn'>
                              Ban User
                            </Button>
                            <Button className='btn-sm detailsBtn' onClick={() => navigate(ROUTES.MANAGE_USER.USER_DETAIL + item._id)}><i class="fa-regular fa-eye"></i></Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                {activeList.length === 0 && !loading && (
                  <p className="dataNotFound text-center">Data not found</p>
                )}
                {loading ? (
                  <div className="text-center">
                    <div
                      class="spinner-border m-2"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CustomModal
        display={modalOpen}
        handleClose={() => setModalOpen(false)}
        size="md"
        className="userModal"
      >
        <StatusChange
          userData={userData}
          setModalOpen={setModalOpen}
          getUser={getUser}
        />
      </CustomModal>

      {totalPage >= 1 && (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

    </div>
  )
}

export default ActiveUser
