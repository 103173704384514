import axios from "axios";
import { ENV_CONSTANTS } from "../Configs/Constants";

export const apiHandlerWithoutFromData = async (
  method,
  url,
  requestBody = {}
) => {
  const token = localStorage.getItem("gameAdminToken");
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const apiHandlerWithFromData = async (method, url, requestBody = {}) => {
  const token = localStorage.getItem("gameAdminToken");
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const apiHandlerOfUserAPI = async (method, url, requestBody = {}) => {
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      data: requestBody,
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};


export const apiHandlerWithUserFormData = async (method, url, requestBody = {}) => {
  const token = localStorage.getItem("gameUserToken");
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const apiHandlerWithUserFromData = async (method, url, requestBody = {}) => {
  const token = localStorage.getItem("gameUserToken");
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: requestBody,
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};