import React, { useState } from "react";
import { Button, Col, Row, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Login from '../../../assets/Images/media/login.png';
import Favicon from '../../../assets/Images/brand/favicon.png';
import ROUTES from "../../../Configs/Routes";

export default function FrontendSignup() {
    return (
        <>
            <div className="page">
                <div className="container-fluid">
                    <Row className="row-contain">
                        <Col lg={7} className="contain-col">
                            <Row className="img-row">
                                <Col lg={12} className="img-col my-auto mx-auto">
                                    <img src={Login} className="login-img my-auto mx-auto"></img>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={5} className="content-div">
                            <div className="login">
                                <div className="container">
                                    <Row>
                                        <Col md={12} className="mx-auto">
                                            <div className="card-sigin">
                                                <div className="mb-5 card-div">
                                                    <img src={Favicon} className="sign-favicon"></img>
                                                    <h1 className="main-logo1 ms-1 me-0 my-auto" >Maidaan Games</h1>
                                                </div>
                                            </div>
                                            <div className="card-sigin">
                                                <div className="main-signup-header">
                                                    <h2>Get Started</h2>
                                                    <h5 className="fw-semibold mb-4">It's free to signup and only takes a minute.</h5>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Label>Firstname & Lastname</Form.Label>
                                                            <Form.Control className="pass-input" placeholder="Enter your firstname and lastname" type="text"></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label className="mt-3">Email</Form.Label>
                                                            <Form.Control className="pass-input" placeholder="Enter your email" type="password"></Form.Control>
                                                            {/* <Button className="signin-btn mt-3">Sign In</Button> */}

                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label className="mt-3">Password</Form.Label>
                                                            <Form.Control className="pass-input" placeholder="Enter your password" type="password"></Form.Control>
                                                            <Button className="signin-btn mt-3">Create Account</Button>

                                                        </Form.Group>
                                                    </Form>

                                                    <Row className="row-xs">
                                                        <Col sm={6} className="facebook-col">
                                                            <Button className="facebook-btn"><i class="fab fa-facebook-f"></i> Signup with Facebook </Button>
                                                        </Col>
                                                        <Col sm={6} className="">
                                                            <Button className="twitter-btn"><i class="fab fa-twitter"></i> Signup with Twitter</Button>
                                                        </Col>
                                                    </Row>
                                                    <div className="main-signin-footer mt-5">
                                                        <p className="mb-2"><Link to={ROUTES.FORGOT_PASSWORD} className="createAccount">Forgot password?</Link></p>
                                                        <p>Already have an account?<span> <Link to={ROUTES.USER} className="createAccount">Sign In</Link></span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

        </>
    )
}