import ROUTES from "./Routes";

const MAIN = [
  {
    LINK: ROUTES.ADMIN_DASHBOARD,
    TITLE: "DashBoard",
    ICON: <i className="las la-home"></i>,
  },
  {
    LINK: "#",
    TITLE: "Manage Users",
    ICON: <i className="fa-regular fa-user side-menu__label"></i>,
    TOGGLE: [
      {
        LINK: ROUTES.MANAGE_USER.LIST,
        TITLE: "All Users",
      },
      {
        LINK: ROUTES.MANAGE_USER.ACTIVE,
        TITLE: "Active Users",
      },
      {
        LINK: ROUTES.MANAGE_USER.BANNED,
        TITLE: "Banned Users",
      },
    ],
  },
  {
    LINK: ROUTES.CATEGORY.LIST,
    TITLE: "Category",
    ICON: <i class="fa-solid fa-list"></i>,
  },
  {
    LINK: ROUTES.GAME.LIST,
    TITLE: "Game",
    ICON: <i class="fa-regular fa-chess-knight"></i>,
  },
  {
    LINK: ROUTES.SOCIAL.LIST,
    TITLE: "Social Media",
    ICON: <i class="fa-regular fa-thumbs-up"></i>,
  },
  {
    LINK: ROUTES.BANNER.LIST,
    TITLE: "Banner",
    ICON: <i class="fa-regular fa-star"></i>,
  },
  {
    LINK: ROUTES.USER_CONTACT,
    TITLE: "User Contact",
    ICON: <i class="fa-solid fa-address-book"></i>,
  },
];

const MENU = {
  MAIN,
};

export default MENU;
