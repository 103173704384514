import React, { useContext, useEffect, useState } from "react";
import { Form, Dropdown, Button, NavItem } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import ROUTES from "../Configs/Routes";
import MainContext from "./Context/MainContext";

export default function Header1() {
  const navigate = useNavigate();

  const changeData = useContext(MainContext);
  const [show, setShow] = useState(false);

  const handleSidebar = () => {
    changeData.setSidebar(true);
  };

  const [admin, setAdmin] = useState({});

  const handleLogout = () => {
    localStorage.removeItem("gameAdminToken");
    navigate(ROUTES.ADMIN_LOGIN);
  };

  return (
    <>
      <div className="d-flex flex-wrap align-items-center w-100">
        <div className="d-flex flex-wrap align-items-center ms-auto">
          <div className="app-sidebar__togglee">
          </div>
          <div className="main-sidebar-header">
            <Link to={ROUTES.ADMIN_DASHBOARD} className="dashboardLogo d-block">
              <img src="sdef" alt="Logo" crossOrigin="anonymous" />
            </Link>
          </div>
          <div className="main-header-right">
            <div className="nav nav-item navbar-nav-right ms-auto">
              <Dropdown className="nav-item main-profile-menu">
                <Dropdown.Toggle className="bg-transparent border-0">
                  <Link className="py-3">
                    <i class="fa-solid fa-bars"></i>
                  </Link>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogout}>
                    <i class="fa-solid fa-arrow-right-from-bracket"></i> Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
