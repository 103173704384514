import React from "react";

export const PrivacyPolicy = () => {
  return (
    <>
      <div className="aboutDiv">
        <div className="my-5">
          <h2 className="aboutMainTitle text-uppercase">Privacy Policy</h2>
          <p className="aboutMainP">Effective Date: 5th May, 2023</p>
        </div>

        <div>
          <ul className="privacy-main">
            <li>Introduction</li>
            <p>
              Welcome to AiGamer.io! This Privacy Policy explains how we
              collect, use, and protect your personal information when you visit
              and use our web platform, AiGamer.io.
            </p>
            <li className="mt-4">Information We Collect</li>
            <p>
              a) Personal Information: We may collect personal information, such
              as your name, email address, and contact details when you register
              or interact with our platform. <br />
              b) Usage Information: We may collect non-personal information
              about your interactions with the platform, such as your IP
              address, device information, and browsing behaviour.
              <br /> c) Usage Data: We collect information about your
              interactions with our website and services, such as pages visited,
              time spent, and other usage statistics. <br />
              d) Cookies: We use cookies and similar tracking technologies to
              enhance your experience on our website, analyse usage, and deliver
              targeted advertising.
            </p>
            <li className="mt-4">Use Of Information</li>
            <p>
              We use the information we collect for various purposes, including:
            </p>
            <ul className="privacy-child">
              <li>
                We use the information we collect for various purposes,
                including:
              </li>
              <li>Enhancing your user experience and personalizing content.</li>
              <li>
                Analysing usage patterns and improving our website and services.
              </li>
              <li>
                Communicating with you, such as for customer support and
                promotional purposes.
              </li>
              <li>
                Detecting, preventing, and addressing technical and security
                issues.
              </li>
            </ul>
            <li className="mt-4">Sharing of Information</li>
            <p>
              We may share your personal information with trusted third parties
              for the purposes outlined in this Privacy Policy. We do not sell
              or rent your personal information to third parties for marketing
              purposes.
            </p>
            <li className="mt-4">Data Security</li>
            <p>
              We implement reasonable security measures to protect your personal
              information from unauthorized access, disclosure, or alteration.
            </p>
            <li className="mt-4">Your Choices and Rights </li>
            <p>
              You have the right to access, correct, or delete your personal
              information. You may also have the right to object to or restrict
              certain processing activities.
            </p>
            <li className="mt-4">Cookies and Tracking Technologies</li>
            <p>
            We use cookies and similar tracking technologies to enhance your
              browsing experience. By using our platform, you consent to the use
              of cookies as described in our Cookie Policy.
            </p>
            <li className="mt-4">Third-Party Links and Services</li>
            <p>
              Our platform may contain links to third-party websites or
              services. We are not responsible for the privacy practices or
              content of such third parties. We encourage you to review their
              privacy policies.
            </p>
            <li className="mt-4">Children's Privacy </li>
            <p>
              Our platform is not intended for children under the age of 13
              years. We do not knowingly collect personal information from
              children. If you believe we have collected personal information
              from a child, please contact us immediately.
            </p>
            <li className="mt-4">Changes to the Privacy Policy</li>
            <p>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be effective upon posting on our
              platform.
            </p>
            <li className="mt-4">Security Measures</li>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal information against unauthorized access,
              alteration, disclosure, or destruction. However, no method of
              transmission over the internet or electronic storage is completely
              secure, and we cannot guarantee absolute security of your
              information.
            </p>
          </ul>
        </div>
      </div>
    </>
  );
};
