// import axios from 'axios';
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/Images/User/AdminLogo.png";
import ROUTES from "../../Configs/Routes";
import MENU from "../../Configs/SidebarMenu";
import MainContext from "../Context/MainContext";

export default function Sidebar() {
  const changeData = useContext(MainContext);

  const [active, setActive] = useState(null);

  const handleToggle = (e) => {
    let classList = e.currentTarget.parentElement.classList;
    if (classList.contains("openToggle")) {
      classList.remove("openToggle");
    } else {
      classList.add("openToggle");
    }
  };

  return (
    <>
      <Button
        className="slideCloseBtn"
        onClick={() => {
          changeData.setSidebar(false);
        }}
      >
        <i className="las la-times"></i>
      </Button>
      <div className="app-sidebar">
        <div className="main-sidebar-header d-flex">
          {!changeData.sidebar && (
            <Link
              as="button"
              className="toggleBtn d-block d-lg-none"
              onClick={() => {
                changeData.setSidebar(!changeData.sidebar);
              }}
            >
              <i className="fa-solid fa-align-left" />
            </Link>
          )}
          <Link to={ROUTES.ADMIN_DASHBOARD} className="desktop-logo">
            <img
              src={Logo}
              alt="Logo"
              crossOrigin="anonymous"
              className="main-logo"
            />
          </Link>
        </div>
        <div className="sidebarScrollDiv ps">
          <div className="main-sidemenu">
            <div className="app-sidebar__user clearfix"></div>
          </div>
          <ul className="side-menu">
            <li className="side-item side-item-category">Main</li>
            {MENU.MAIN.map((item, index) => {
              return (
                <>
                  {!item.TOGGLE ? (
                    <>
                      <li
                        className={
                          window.location.href.includes(item.LINK)
                            ? "slide sidebarMenuItem active-color "
                            : "slide sidebarMenuItem"
                        }
                        key={index}
                        onClick={() => {
                          changeData.setSidebar(false);
                        }}
                      >
                        <Link
                          to={item.LINK}
                          id={index}
                          className="side-menu__item"
                        >
                          <span className="side-menu__label">{item.ICON}</span>
                          {item.TITLE}
                        </Link>
                        <span
                          className="badge bg-success text-light"
                          id="bg-side-text"
                        >
                          1
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className={
                          index === active
                            ? "slide sidebarMenuItem toggleMenu activeMenu openToggle"
                            : "sidebarMenuItem toggleMenu"
                        }
                        key={index}
                      >
                        <Link
                          to={item.LINK}
                          onClick={(e) => {
                            handleToggle(e);
                          }}
                          id={index}
                        >
                          <span>{item.ICON}</span>
                          {item.TITLE}
                        </Link>

                        <ul className="toggleUl">
                          {item.TOGGLE.map((child, id) => {
                            return (
                              <li
                                className="sidebarMenuItem toggleItem "
                                key={id}
                              >
                                <Link
                                  to={child.LINK}
                                  id={id}
                                  className="side-menu__item"
                                >
                                  <span>
                                    <i className="las la-dot-circle"></i>
                                  </span>
                                  {child.TITLE}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
