import React, { useState, useEffect } from 'react'
import { Table, Button, Col, Row, Card, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { GetCategoryTrackDetails, GetGameTrackDetailsWithSearch } from "../../../APIs/AdminAll_api";
import Pagination from "../Pagination";

function CategoryTrack() {
	const { id } = useParams()

	const [loading, setLoading] = useState(true);
	const [categoryList, setCategoryList] = useState([])
	const [totalRecord, setTotalRecord] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState("");
	const [search, setSearch] = useState("");

	const getAllCategory = async () => {
		setLoading(true);
		const data = await GetCategoryTrackDetails(id, currentPage);
		if (data.status === 200) {
			setLoading(false);
			setTotalRecord(data?.data.totalrecord);
			setCategoryList(data.data.data);
		}
	};

	useEffect(() => {
		if (!search) {
			getAllCategory();
		}
	}, [currentPage, search]);

	useEffect(() => {
		if (totalRecord || totalRecord === 0) {
			var page = totalRecord / 10;
			setTotalPage(page);
		}
	}, [totalRecord]);

	const searchApi = async () => {
		setLoading(true);
		try {
			const res = await GetGameTrackDetailsWithSearch(id, search, currentPage);
			if (res.status === 200) {
				setLoading(false);
				setTotalRecord(res?.data.totalrecord);
				setCategoryList(res.data.data);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (search) {
			searchApi();
		}
	}, [currentPage])

	const handleSearch = (e) => {
		e.preventDefault();
		if (search) {
			searchApi();
			setCurrentPage(1)
		}
	};

	return (
		<div className="container-fluid">
			<div className="dashboard-header">
				<div className="my-auto">
					<div className="d-flex">
						<h4 className="content-title mb-0 my-auto">Category</h4>
					</div>
				</div>
				<div className="d-flex my-xl-auto right-content align-items-center">
					<div className="dashPageSearch me-2">
						<Form className="d-flex flex-wrap" onSubmit={handleSearch}>
							<Form.Group controlId="userSearch" >
								<Form.Control
									type="search"
									required
									name="search"
									placeholder="Search Game..."
									value={search}
									onChange={(e) => { setSearch(e.target?.value) }}
								/>
							</Form.Group>
							<Button type="submit" className="search-btn">
								<i className="fa-solid fa-magnifying-glass"></i>
							</Button>
						</Form>
					</div>
				</div>
			</div>
			<Row className="row-sm">
				<Col md={12}>
					<Card>
						<Card.Body>
							<div className="table-responsive border-top userlist-table ">
								<Table
									responsive
									className="table card-table table-hover table-vcenter text-nowrap mb-3"
								>
									<thead className="mb-3">
										<tr className="mb-3 head-color">
											<th>Name</th>
											<th>image</th>
											<th>Count </th>
											<th>Status</th>
											<th style={{ width: "30px" }}>
												<span>Action</span>
											</th>
										</tr>
									</thead>
									{!loading &&
										categoryList.map((item, index) => {
											return (
												<tbody>
													<tr>
														<td>{item?.name}</td>
														<td>
															<img
																src={item.image}
																className="category-image"
															/>
														</td>
														<td>{item?.count}</td>
														<td>{item.status ? "Active" : "Inactive"}</td>
														<td>
															<Link
																class="btn btn-sm btn-info btn-b"
																to={`${ROUTES.CATEGORY.UPDATE}/${item._id}`}
															>
																<i class="las la-pen"></i>
															</Link>
														</td>
													</tr>
												</tbody>
											);
										})}
								</Table>
								{loading ? (
									<div className="text-center">
										<div
											class="spinner-border m-2"
											style={{ width: "3rem", height: "3rem" }}
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{totalPage >= 1 && (
				<Pagination
					totalPage={totalPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</div>
	)
}

export default CategoryTrack