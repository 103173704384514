import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import MainContext from "../Context/MainContext";
import Header1 from "../Header1";
import Sidebar from "./Sidebar";

const Layout = () => {
  const navigate = useNavigate();
  const changeData = useContext(MainContext);

  useEffect(() => {
    if (!localStorage.getItem("gameAdminToken")) {
      navigate(ROUTES.ADMIN_LOGIN);
    }
  }, []);

  return (
    <div className="dashboardWrapper">
      <div
        className={`${
          changeData.sidebar ? "sidebarWrapper sidebarToggle" : "sidebarWrapper"
        }`}
      >
        <div>
          <Sidebar />
        </div>
      </div>
      <div className="rightSideWrapper">
        <div className="headerWrapper">
          <Header1 />
        </div>
        <div className="outletWrapper">
          <div className="outlet-main">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
