import ReactPaginate from 'react-paginate';

const Pagination = ({ totalPage, setCurrentPage }) => {
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
    };

    return (
        <div>
            <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                pageCount={Math.ceil(totalPage)}
                onPageChange={handlePageClick}
                containerClassName="adminPagination"
                previousLinkClassName="paginationLink paginationLinkPrev"
                nextLinkClassName="paginationLink"
                disabledClassName="paginationDisabled"
                activeClassName="paginationActive"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
            />
        </div>
    );
};

export default Pagination;
