import React, { useState } from 'react'
import { useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getStatusChangeUserApi } from '../../../APIs/AdminAll_api'

const StatusChange = ({ userData, setModalOpen, getUser }) => {
  const [loading, setLoading] = useState(false)

  const handleStatus = async (e) => {
    setLoading(true)
    e.preventDefault();
    const formData = {
      status: userData?.status
    }
    const maunal = await getStatusChangeUserApi(formData, userData._id)
    if (maunal.status === 200) {
      setLoading(false)
      toast.success(maunal.data?.message);
      setModalOpen(false);
      getUser();
    }
    else if (maunal.status === 422) {
      setLoading(false)
      toast.error(maunal.response.data.message);
    }
  }

  return (
    <div>
      <>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation Alert!</Modal.Title>
        </Modal.Header>
        <Form className='adminForm' onSubmit={handleStatus}>
          <Modal.Body>
            <p><span>
              Are you sure to {userData?.status ? 'ban' : 'unban'} this user?
            </span></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalOpen(false)}>No</Button>
            <Button variant='primary' type='submit' className='modalSubmit'>{loading ? <div className="text-center">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div> : "Yes"}</Button>
          </Modal.Footer>
        </Form>

      </>
    </div>
  )
}

export default StatusChange
