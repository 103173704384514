// logout ,deleteaccount

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form } from 'reactstrap'
import ROUTES from '../Configs/Routes'
import AiGamer from "../assets/Images/User/AdminLogo.png";
import { Button, FormGroup, Spinner } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { toast } from 'react-toastify';
import { OtplessApi } from "../APIs/UserAll_api";
import { useContext } from 'react';
import MainContext from './Context/MainContext';
import { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import { initOTPless } from "../Utils/initOtpless";
import Modal from 'react-bootstrap/Modal';

const UserLogin = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isloading, setLoading] = useState(false)
  const [showWhattsup, setShowWhattsup] = useState(false)

  const [countryCode, setCountryCode] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleSignUp = async (e, otplessUser) => {
    const data = {
      token: otplessUser.token,
    };

    setLoading(true);
    const respData = await OtplessApi(data);
    if (respData?.status === 200) {
      navigate("/");
      toast.success(respData.data.message)
      context.setSignupData(data)
      setLoading(false);
    }
    else {
      toast.error(respData.response.data.message)
      setLoading(false)
    }
  };



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsValue = urlParams.get("ex");
    if (paramsValue) {
      openModal();
    }
  }, []);

  const openModal = () => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const paramsValue = urlParams.get("ex");
    // if (!paramsValue) {
    //   const currentURL = window.location.href;
    //   const newParam1 = "ex=true";
    //   const updatedURL = `${currentURL}?${newParam1}`;
    //   window.history.pushState(null, "", updatedURL);
    // }
    // initOTPless();
    // const modalContainer = document.getElementById("modalContainer");
    // const modal = document.getElementById("otpless-login-page");
    // modalContainer.style.display = "flex";
    // modal.style.display = modal.style.display === "block" ? "none" : "block";

    // const script = document.createElement("script")
    // script.src = "https://otpless.com/auth.js";
    // script.id = "otplessIdScript";
    // document.body.appendChild(script)
    // window.otpless = (otplessUser) => {
    //   alert(JSON.stringify(otplessUser));
    //   navigate('/')
    // };
  };

  const closeModal = (e) => {
    //   const currentURL = window.location.href;
    //   const urlWithoutParams = currentURL.split("?")[0];
    //   window.history.pushState(null, "", urlWithoutParams);
    //   const modalContainer = document.getElementById("modalContainer");
    //   if (e.target === modalContainer) {
    //     modalContainer.style.display = "none";
    //   }
  };

  useEffect(() => {
    if (show) {
      const script = document.createElement("script")
      script.src = "https://otpless.com/auth.js";
      script.id = "otplessIdScript";
      document.body.appendChild(script)
      window.otpless = (otplessUser) => {
        alert(JSON.stringify(otplessUser));
        handleSignUp(otplessUser)
      };

      return () => {
        const divid = document.getElementById("otplessIdScript");
        document.body.removeChild(divid);
      };
    }
  }, [show]);


  return (
    <div className='login-page'>
      <div className='main'>
        <div className="login-container">
          <div className="sign-in" style={{ padding: "32px" }}>
            <div className='arrow'>
              <Link to={ROUTES.USER}>
                <i class="fa-solid fa-arrow-left"></i>
              </Link>
              <img src={AiGamer} alt='' className="user-aigamer me-5 "></img>
            </div>

            <div className='text-dark mt-2 mb-2'>
              <p>Log in with AiGamer</p>
            </div>

            <div className='d-flex justify-content-center '>
              <button className='mt-3 me-2 social-btn'>
                <span className='facebook-icon'><i className="fa-brands fa-facebook-f" /></span>
              </button>
              <button className='mt-3 social-btn'
                onClick={() => navigate(ROUTES.OTPLESS)}
              // onClick={openModal}
              // onClick={handleShow}
              >
                <span className='whatsapp-icon'><i class="fa-brands fa-whatsapp"></i></span>
              </button>
            </div>

          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div id="otpless-login-page"></div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UserLogin
