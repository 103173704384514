import React from 'react'

export const About = () => {
    return (
        <>
            <div className='aboutDiv'>
                <div className='my-5'>
                    <h2 className='aboutMainTitle text-uppercase'>About Us</h2>
                    <p className='aboutMainP'>Welcome to AiGamer.io! We are a passionate game development company on a mission to create unforgettable gaming experiences and foster a thriving community of gamers. Our talented team of developers, designers, and creative thinkers are committed to delivering the most innovative, immersive, and engaging games for players worldwide. With a strong focus on building a vibrant gamer community, we're excited to invite you to be a part of our journey at aigamer.io.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Our Story</h3>
                    <p className='aboutP'><span className='fw-bold'>Founded in 2023,</span> AiGamer started as a small group of gaming enthusiasts with a shared dream of creating games that truly resonate with players. Over the years, we've grown into a diverse and dedicated team of professionals who are tirelessly working to push the boundaries of game development. Our passion for games is evident in every title we create, and we're constantly inspired by the stories, emotions, and adventures that the gaming world has to offer.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Our Games</h3>
                    <p className='aboutP'>At AiGamer, we believe in crafting games that are not only visually stunning and mechanically polished but also capable of creating a deep connection with our players. Our portfolio spans across various genres, from action-packed adventures to immersive story-driven experiences. We are committed to constantly exploring new frontiers in game design and technology to deliver fresh and exciting content to our ever-growing gamer community.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Building a Community</h3>
                    <p className='aboutP'>Our focus goes beyond just developing outstanding games – we strive to create a tight-knit gaming community where everyone feels welcome and engaged. Our website, aigamer.io, serves as a hub for gamers to come together, discuss their favorite titles, share tips and strategies, and participate in exciting events and tournaments. We believe that by fostering a strong sense of camaraderie among our players, we can elevate the gaming experience to new heights.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Our Vision</h3>
                    <p className='aboutP'>At AiGamer, our vision is to become a leading force in the gaming industry by creating captivating and innovative games that stand the test of time. We are driven by our passion for the art of game development and the belief that games have the power to connect people, inspire creativity, and evoke emotions like no other medium. We aim to create a legacy of memorable gaming experiences that leave a lasting impact on our players and the industry as a whole.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Our Mission</h3>
                    <p className='aboutP'>At AiGamer, our mission is to create captivating, inclusive, and immersive gaming experiences that resonate with players across the globe. We are dedicated to fostering a thriving and diverse gaming community, united by our shared passion for the art of gaming. By combining innovation, collaboration, and sustainability, we strive to redefine the boundaries of game development and establish ourselves as a leading force in the gaming industry. Our unwavering commitment to quality, creativity, and integrity guides us in our pursuit to connect, inspire, and empower gamers worldwide.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Our Values</h3>
                    <p className='aboutP mb-3'>In everything we do at AiGamer, we remain committed to our core values:</p>
                    <ol className='aboutP'>
                        <li className='mb-2'><span className='fw-bold'>Creativity:</span> We believe in pushing the boundaries of imagination, embracing original ideas, and exploring unconventional solutions to create unique and engaging gaming experiences.</li>
                        <li className='mb-2'><span className='fw-bold'>Collaboration:</span> We work as a unified team, fostering an open and inclusive environment where everyone's voice is heard and respected, and where diverse perspectives come together to create the best possible games.</li>
                        <li className='mb-2'><span className='fw-bold'>Quality:</span> We are dedicated to delivering the highest level of quality in every aspect of our games, from visuals and gameplay to storytelling and sound design. We take pride in our work and constantly strive to improve and refine our craft.</li>
                        <li className='mb-2'><span className='fw-bold'>Community:</span> We recognize the importance of building a strong gamer community and are dedicated to creating a supportive and inclusive space where players can connect, share, and grow together.</li>
                        <li className='mb-2'><span className='fw-bold'>Integrity:</span> We conduct ourselves with honesty and transparency, always putting the needs and expectations of our players and partners first.</li>
                    </ol>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Join Us</h3>
                    <p className='aboutP mb-2'>We invite you to be a part of the AiGamer family by visiting our website and joining our thriving community of gamers. Stay up to date with the latest news, events, and game releases, and connect with like-minded gamers from around the world. Together, we'll continue to push the boundaries of gaming and create unforgettable experiences for years to come.</p>
                    <p className='aboutP'>Welcome to the future of gaming. Welcome to AiGamer.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Your Feedback Matters</h3>
                    <p className='aboutP'>As we continue to grow and evolve, we value the input and feedback of our community. By listening to our players and incorporating their ideas and suggestions into our development process, we can create games that are truly shaped by the gamers themselves. We encourage you to share your thoughts, opinions, and ideas with us through our website, social media channels, and community forums. Your voice matters, and together we can create games that we can all be proud of.</p>
                </div>

                <div className='my-4'>
                    <h3 className='aboutTitle'><span><i className="fa-solid fa-star"></i></span>Stay Connected</h3>
                    <p className='aboutP mb-2'>Don't miss out on any AiGamer updates or announcements! Follow us on social media and subscribe to our newsletter to stay in the loop with the latest news, game releases, and exclusive content. Be a part of our exciting journey as we continue to push the boundaries of game development and build a community of passionate gamers.</p>
                    <p className='aboutP'>Thank you for being a part of our journey and for supporting AiGamer as we work towards building a brighter, more inclusive, and sustainable gaming future for everyone. Together, we can make a difference and create a world of gaming that truly leaves a lasting legacy.</p>
                </div>

            </div>
        </>
    )
}
