import React from "react";
import { Col, Row } from "react-bootstrap";
import ROUTES from "../Configs/Routes";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div className="user-main PageNotFound-hidden">
      <Row className="pagenotFound-main">
        <Col sm={10} md={8} lg={7} xl={5} className="mx-auto">
          <div className="text-center pagenotFound ">
            <p>404</p>
            <span>Page Not Found</span>

            <div className="mt-5">
              <Link to={ROUTES.USER}>Back to home</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
