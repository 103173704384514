
import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Col,
  Row,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import { createGameApi } from "../../../APIs/AdminAll_api";
import { getAllcategoryApi } from "../../../APIs/UserAll_api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";

const AddGame = () => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [uploadZip, setUploadZip] = useState("");
  const [video_file, setVideo_File] = useState("");
  const [status, setStatus] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadAsset, setUploadAsset] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();

    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;
    const imageExt = image.name?.split(".").pop();
    const videoExt = video_file.name?.split(".").pop();
    const uploadZipExt = uploadZip.name?.split(".").pop();
    const uploadAssetExt = uploadAsset.name?.split(".").pop();

    if (name === "") {
      setLoading(false);
      toast.error("Please enter game name");
    } else if (name.trim() !== "" && (re1.test(name) || !re.test(name))) {
      setLoading(false);
      toast.error("Please enter a valid game name");
    } else if (
      image?.name &&
      imageExt !== "jpg" &&
      imageExt !== "jpeg" &&
      imageExt !== "png" &&
      imageExt !== "svg"
    ) {
      setLoading(false);
      toast.error("Please upload valid image");
    }
    // else if (
    //   uploadZip?.name &&
    //   uploadZipExt !== "zip" &&
    //   uploadZipExt !== "rar" &&
    //   uploadZipExt !== "7zip" &&
    //   uploadZipExt !== "rar4"
    // ) {
    //   setLoading(false);
    //   toast.error("Please upload valid zip file");
    // } 
    else if (
      video_file &&
      videoExt !== "mp4" &&
      videoExt !== "mkv" &&
      videoExt !== "avi" &&
      videoExt !== "mov" &&
      videoExt !== "wmv"
    ) {
      setLoading(false);
      toast.error("Please upload valid video file");
    }
    else if (
      uploadAsset?.name &&
      uploadAssetExt !== "zip" &&
      uploadAssetExt !== "rar" &&
      uploadAssetExt !== "7zip" &&
      uploadAssetExt !== "rar4"
    ) {
      setLoading(false);
      toast.error("Please upload valid zip file");
    }
    else {
      const formData = new FormData();
      if (name) {
        formData.append("name", name);
      }
      if (category) {
        formData.append("category_id", category);
      }
      if (description) {
        formData.append("description", "");
      }
      formData.append("status", status);
      if (image?.name) {
        formData.append("image", image);
      }
      if (video_file?.name) {
        formData.append("video_file", video_file);
      }
      if (uploadZip?.name) {
        formData.append("webgl_zip", uploadZip);
      }
      if (uploadAsset?.name) {
        formData.append("asset_zip", uploadAsset);
      }
      setLoading(true);

      const data = await createGameApi(formData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.GAME.LIST);
        toast.success("Game Added Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  const getAllCategory = async () => {
    const data = await getAllcategoryApi();
    if (data.status === 200) {
      setAllCategory(data.data.data);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Game</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Add Game</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={6}>
          <Card className="box-shadow-0">
            <Card.Body>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name
                  </Form.Label><label className="text-danger">*</label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    id="inputEmail"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Category
                  </Form.Label>
                  <Form.Select

                    className="filter-card2"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    name="categoty"
                    value={category}
                  >
                    <option value="" disabled selected>
                      Select Category
                    </option>
                    {allCategory.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Image (512*512)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}

                  />
                </Form.Group>
                {/* <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Zip
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setUploadZip(e.target.files[0]);
                    }}
                    accept=".zip,.rar,.7zip,.rar4"

                  />
                </Form.Group> */}
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Video</Form.Label> (512*512)
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setVideo_File(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Game Asset
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setUploadAsset(e.target.files[0]);
                    }}
                    accept=".zip,.rar,.7zip,.rar4"

                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                    Status
                  </Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Check
                      type="radio"
                      label="Active"
                      name="status"
                      value="true"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Inactive"
                      name="status"
                      value="false"
                      checked={!status}
                      onChange={handleStatusChange}
                    />
                  </div>
                </Form.Group>
                <div>
                  <Button type="submit" className="editBtn">
                    {loading ? (
                      <div
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                    Submit
                  </Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => {
                      navigate(ROUTES.GAME.LIST);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddGame;
