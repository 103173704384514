import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { getAllCategoryApi } from "../../../APIs/AdminAll_api";
import CustomModal from "../../../Configs/CustomModal/CustomModal";
import DeleteCategory from "./DeleteCategory";
import Pagination from "../Pagination";

const Index = () => {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [Delete, setDelete] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");

  const getAllCategory = async () => {
    setLoading(true);
    const data = await getAllCategoryApi(currentPage);
    if (data.status === 200) {
      setLoading(false);
      setTotalRecord(data?.data.total_record);
      setCategoryList(data.data.data);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, [currentPage]);

  const handleAddCategory = () => {
    navigate(ROUTES.CATEGORY.ADD);
  };

  const handleDelete = (index) => {
    setDelete(true);
    setCategoryId(index);
  };

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / 10;
      setTotalPage(page);
    }
  }, [totalRecord]);

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Category</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content">
          <div className="pe-1 mb-xl-0">
            <Button
              type="button"
              className="btn add-btn"
              onClick={handleAddCategory}
            >
              <i className="fa-regular fa-plus m-1"></i>
              Add Category
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive border-top userlist-table ">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3"
                >
                  <thead className="mb-3">
                    <tr className="mb-3 head-color">
                      <th>Name</th>
                      <th>image</th>
                      <th>Status</th>
                      <th style={{ width: "30px" }}>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  {!loading &&
                    categoryList.map((item, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{item?.name}</td>
                            <td>
                              <img
                                src={item.image}
                                className="category-image"
                              />
                            </td>
                            <td>{item.status ? "Active" : "Inactive"}</td>
                            <td>
                              <Link
                                class="btn btn-sm btn-info btn-b"
                                to={`${ROUTES.CATEGORY.UPDATE}/${item._id}`}
                              >
                                <i class="las la-pen"></i>
                              </Link>
                              <Link
                                class="btn btn-sm btn-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i class="las la-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
                {loading ? (
                  <div className="text-center">
                    <div
                      class="spinner-border m-2"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CustomModal
        display={Delete}
        handleClose={() => setDelete(false)}
        size="md"
        className="userModal"
      >
        <DeleteCategory
          setDelete={setDelete}
          categoryId={categoryId}
          getAllCategory={getAllCategory}
        />
      </CustomModal>
      {totalPage >= 1 && (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default Index;
