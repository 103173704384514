import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { getAllBannerApi } from "../../../APIs/AdminAll_api";
import Game1 from "../../../assets/Images/User/Games/game1.png";
import CustomModal from "../../../Configs/CustomModal/CustomModal";
import DeleteCategory from "./DeleteBanner";
import Pagination from "../Pagination";

const Index = () => {
  const navigate = useNavigate();
  const [allBanner, setAllBanner] = useState([]);
  const [Delete, setDelete] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [totalRecord, setTotalRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const handleAddBanner = () => {
    navigate(ROUTES.BANNER.ADD);
  };

  const getAllBanner = async () => {
    setLoading(true);
    const data = await getAllBannerApi(currentPage);
    if (data.status === 200) {
      setLoading(false);
      setTotalRecord(data?.data.total_record);
      setAllBanner(data.data.data);
    }
  };

  useEffect(() => {
    getAllBanner();
  }, [currentPage]);

  const handleDelete = (index) => {
    setDelete(true);
    setBannerId(index);
  };

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / 10;
      setTotalPage(page);
    }
  }, [totalRecord]);

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Banner</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content">
          <div className="pe-1 mb-xl-0">
            <Button
              type="button"
              className="btn add-btn"
              onClick={handleAddBanner}
            >
              <i className="fa-regular fa-plus m-1"></i>
              Add Banner
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive border-top userlist-table ">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3"
                >
                  <thead className="mb-3">
                    <tr className="mb-3 head-color">
                      <th>Name</th>
                      <th className="text-center">Image</th>
                      <th>Status</th>
                      <th style={{ width: "30px" }}>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  {!loading &&
                    allBanner.length > 0 &&
                    allBanner.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{item.name}</td>
                            <td className="text-center">
                              {item.image ? (
                                <img
                                  src={item.image}
                                  s
                                  alt="game"
                                  className="game-image"
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {item.status === true ? "Active" : "In active"}
                            </td>
                            <td>
                              <Link
                                class="btn btn-sm btn-info btn-b"
                                to={`${ROUTES.BANNER.UPDATE}/${item._id}`}
                              >
                                <i class="las la-pen"></i>
                              </Link>
                              <Link
                                class="btn btn-sm btn-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i class="las la-trash"></i>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
                {loading ? (
                  <div className="text-center">
                    <div
                      class="spinner-border m-2"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CustomModal
        display={Delete}
        handleClose={() => setDelete(false)}
        size="md"
        className="userModal"
      >
        <DeleteCategory
          setDelete={setDelete}
          bannerId={bannerId}
          getAllBanner={getAllBanner}
        />
      </CustomModal>
      {totalPage >= 1 && (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default Index;
