import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { contactApi } from "../../APIs/UserAll_api";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      full_name: name,
      email: email,
      mobile_number: mobile,
      subject: subject,
      message: message,
    };
    const data = await contactApi(formData);
    if (data.status === 200) {
      setLoading(false);
      toast.success("Sent successfully");
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="aboutDiv">
        <div className="my-5">
          <h2 className="aboutMainTitle text-uppercase text-center">
            Contact Us
          </h2>
        </div>

        <Container fluid className="p-0">
          <Row>
            <Col sm={10} md={8} lg={7} xl={5} className="mx-auto">
              <Form className="contactForm" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    id="inputEmail"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    id="inputEmail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Mobile Number"
                    id="inputEmail"
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Subject"
                    id="inputEmail"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter Message"
                    id="inputEmail"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Button type="submit" className="contactBtn">
                  {loading ? (
                    <p className="text-center">
                      <Spinner
                        className="m-1 border-1"
                        animation="border"
                        role="status"
                        style={{ width: "15px", height: "15px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </p>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
