import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Col,
  Row,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import { createLinkApi } from "../../../APIs/AdminAll_api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";

const AddGame = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      name: name,
      url: link,
    };
    setLoading(true);
    const data = await createLinkApi(body);
    if (data.status === 200) {
      setLoading(false);
      navigate(ROUTES.SOCIAL.LIST);
      toast.success("Game Added Successfully");
    } else if (data.response.data.status === 422) {
      setLoading(false);
      toast.error(data.response.data.message);
    }
    else {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };


  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Social Links</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Add Link</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={6}>
          <Card className="box-shadow-0">
            <Card.Body>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name<label className="text-danger">*</label>
                  </Form.Label>
                  <Form.Select
                    required
                    className="filter-card2"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    name="name"
                    value={name}
                  >
                    <option value="" disabled selected>
                      Select Name
                    </option>
                    <option value="instagram">Instagram</option>
                    <option value="youtube">You Tube</option>
                    <option value="linkdin">Linkdin</option>
                    <option value="twitter">Twitter</option>
                    <option value="facebook">Facebook</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Link"
                    id="inputEmail"
                    name="link"
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <div>
                  <Button type="submit" className="editBtn">
                    {loading ? (
                      <div
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                    Submit
                  </Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => {
                      navigate(ROUTES.SOCIAL.LIST);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddGame;
