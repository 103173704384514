import React, { useContext } from 'react'
import MainContext from './Context/MainContext';
import UserIcon from "../assets/Images/download.jpg"
import { ENV_CONSTANTS } from '../Configs/Constants';
import { useState } from 'react';

const IMG_PREFIX = ENV_CONSTANTS.REACT_APP_SERVER_URL

const UserProfile = () => {

  const context = useContext(MainContext);

  const [userName, setUserName] = useState()


  return (
    <div>
      <div class="offcanvas offcanvas-end rightCanvas" tabindex="-1" id="offcanvasRight2">
        <div class="offcanvas-header">
          <div type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"><i class="fa-solid fa-angle-left"></i></div>
          <div class="offcanvas-header">
            <p></p>
            <div type="button" data-bs-dismiss="offcanvas">
              <span className="closeIcon"><i class="fa-solid fa-xmark"></i></span>
            </div>
          </div>
        </div>
        <div style={{ padding: "10px 50px" }}>
          <span className='dashUserIcon2 me-2 mx-3'>
            <img src={context.adminData.image ? `${context.adminData.image}` : UserIcon} alt='image' />

          </span>

          <div className="edit-input">
            <div className="edit-mail">
              <span className="me-2">
                <i className="fa-regular fa-user"></i>
              </span>
              <span className="enter">UserName :- </span>
            </div>
            <div className="p-2 mt-2 d-flex justify-content-between edit-input-value">
              {/* <p className="">{context.adminData?.username}</p> */}
              <p className="">{userName ? userName : context.adminData?.username}</p>
            </div>
          </div>

          {context.adminData?.email && <div className="edit-input mt-3">
            <div className="edit-mail">
              <span className="me-2">
                <i className="fa-regular fa-envelope"></i>
              </span>
              <span className="enter">Email :- </span>
            </div>
            <div className="p-2 mt-2 d-flex justify-content-between edit-input-value">
              <p className="">{context.adminData?.email}</p>
            </div>
          </div>}

        </div>

      </div>

    </div>
  )
}

export default UserProfile
