import React, { useState } from "react";
import { Button, Col, Row, Container, Form } from "react-bootstrap";
import Reset from '../../../assets/Images/media/reset.png';
import Favicon from '../../../assets/Images/brand/favicon.png';
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";

export default function ResetPassword() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(ROUTES.USER)
  }

  return (
    <>
      <div className="page">
        <div className="container-fluid">
          <Row className="row-contain">
            <Col md={7} className="contain-col">
              <Row className="img-row mx-auto">
                <Col lg={12} className="img-col my-auto mx-auto d-md-flex">
                  <img src={Reset} className=" mx-auto my-auto" style={{ paddingTop: "300px" }}></img>
                </Col>
              </Row>
            </Col>
            <Col md={6} xl={5} lg={6} className="content-div">
              <div className="login py-2">
                <div className="container mx-auto my-auto">
                  <Row>
                    <Col md={11} className="mx-auto">
                      <div className="card-sigin">
                        <div className="mb-5 card-div">
                          <img src={Favicon} className="sign-favicon"></img>
                          <h1 className="main-logo1 ms-1 me-0 my-auto" >Aigamer Games</h1>
                        </div>
                      </div>
                      <div className="card-sigin">
                        <div className="main-signup-header">
                          <h2>Welcome back!</h2>
                          <h5 className="fw-semibold mb-4">Reset Your Password</h5>
                          <Form>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control className="pass-input" placeholder="Enter your Email" type="text"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Form.Label>New Password</Form.Label>
                              <Form.Control className="pass-input" placeholder="Enter your password" type="password"></Form.Control>
                            </Form.Group>
                            <Form.Group className="mt-3">
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control className="pass-input" placeholder="Enter your password" type="password"></Form.Control>
                            </Form.Group>

                            <Button onClick={handleClick} className="signin-btn mt-3"><Link style={{ textDecoration: "none", color: "#fff" }}>Reset Password</Link></Button>

                          </Form>
                          <div className="main-signin-footer mt-3">
                            <p>Already have an account?<Link to={ROUTES.USER_SIGNUP} className="createAccount"> Sign In</Link></p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div >
    </>
  )


}