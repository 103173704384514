import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import ForgotImg from "../../assets/Images/media/forgot.png";
import { toast } from 'react-toastify'
import axios from 'axios'


const BASE_URL = process.env.REACT_APP_SERVER_URL;
export default function AdminForgetPassword() {
  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    const forgetPasswordApi = `${BASE_URL}api/v1/admins/forgot-password`;
    const data = {
      email: email
    }
    axios({
      method: "POST",
      url: forgetPasswordApi,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // navigate(ROUTES.ADMIN_DASHBOARD);
          setEmail('')
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      })
  }




  return (
    <>
      <div className="page">
        <div className="container-fluid">
          <Row className="no-gutter">
            <Col md={7} className="contain-col">
              <Row className="img-row">
                <Col lg={12} className="img-col my-auto mx-auto">
                  <img src={ForgotImg} alt='forgot' className="login-img my-auto mx-auto" />
                </Col>

              </Row>
            </Col>
            <Col md={6} xl={5} lg={6} className="content-div">
              <div className="login">
                <div className="container">
                  <Row>
                    <Col md={11} className="mx-auto">

                      <div className="card-sigin">
                        <div className="main-signup-header">
                          <h2>Forgot Password!</h2>
                          <h5 className="fw-semibold mb-4">Please Enter Your Email</h5>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control className="pass-input" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Enter your Email" type="email" required></Form.Control>
                            </Form.Group>
                            <Button type="submit" className="signin-btn mt-3">Send</Button>
                          </Form>
                          <div className="main-signin-footer mt-3">
                            <Link to={ROUTES.ADMIN_LOGIN} className="createAccount">Login Here</Link>
                          </div>
                        </div>
                      </div >
                    </Col >

                  </Row >
                </div >

              </div >
            </Col >
          </Row >

        </div >

      </div >
    </>
  )
}