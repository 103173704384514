import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { TrackGame, getCategoryWiseDataApi } from "../../../APIs/UserAll_api";
import MainContext from "../../Context/MainContext";

const Categorywise = () => {
  const { catId } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const [game, setGame] = useState([]);
  const [loading, setLoading] = useState(true);
  const data = useContext(MainContext);

  const getCategoryWiseData = async () => {
    try {
      setLoading(true);
      const res = await getCategoryWiseDataApi(location.state.category_id);
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePlayGame = async (id, name) => {

    data.setGameName(name)
    const decodedParam = decodeURIComponent(name);
    const mergedParam = decodedParam.split(' ').join('').toLowerCase();

    navigate(`${ROUTES.PLAY_GAME.LIST}/${mergedParam}`, { state: { game_id: id } });

    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: id
      }
      await TrackGame(body)
    }
  };

  useEffect(() => {
    getCategoryWiseData();
  }, [catId]);

  const handleHover = (e) => {
    e.target.play();
  };

  const handleHoverOut = (e) => {
    e.target.currentTime = 0;
    e.target.pause();
  };

  return (
    <div>
      {!loading && game.length === 0 && (
        <h2 className="text-center text-white">No Games Found</h2>
      )}
      <div className="CategoryGamesDiv mt-4">
        {!loading &&
          game.length > 0 &&
          game.map((game) => {
            return (
              <div
                className="alsolike-grid"
                key={game}
                onClick={() => {
                  handlePlayGame(game._id, game.name);
                }}
              >
                {game.video_file && (
                  <video
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseLeave={(e) => handleHoverOut(e)}
                    className="homeVideo"
                    src={game.video_file}
                    unmuted
                    loop
                    onClick={() => {
                      handlePlayGame(game._id, game.name);
                    }}
                  ></video>
                )}
                <img
                  src={game.image}
                  alt="image"
                  className={game.video_file ? "homeimage" : ""}
                />
                <span className="gameName">{game.name}</span>
              </div>
            );
          })}
      </div>
      {loading ? (
        <div className="text-center">
          <div
            class="spinner-border text-white"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div>
        {!loading &&
          <div className="p-4 gamecategory-description">
            <div dangerouslySetInnerHTML={{ __html: data.categoryDescription }} />
            <div className="mt-4">
              <ul>
                {game.map((game) => {
                  data.setGameName(game.name)
                  const decodedParam = decodeURIComponent(game.name);
                  const mergedParam = decodedParam.split(' ').join('').toLowerCase();
                  return (
                    <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${mergedParam}`}>{game.name}</Link></li>
                  );
                })}
              </ul>
            </div>
          </div>
        }
      </div>

      {/* {
        !loading && game[0]?.category_id === "64538f827cdb7484b9b601d1" ?
          <div className="p-4 gamecategory-description">
            <div>
              <p className="mt-3 description-details">Fighting games became popular, thanks to the iconic Street Fighter arcade game and later Mortal Kombat further expanded the genre during the heyday of early gaming consoles. Nowadays you can enjoy a variety of fighting games right, in your web browser like in AIGAMER.io. From battles and kung fu showdowns to wrestling matches and intense boxing bouts there is a diverse range of options to choose from. </p>
            </div>
            <div>
              <p className="description-title mt-4">What are Fighting Games?</p>
              <p className="mt-3 description-details">Engage in combat with Fighting Games</p>
              <p className="mt-3 description-details">When you're bursting with energy and itching for some action of picking fights with strangers and causing trouble why not channel that vigor into playing AIGamer selection of fighting games? Test your mettle in these free online games. Showcase your prowess.</p>
              <p className="mt-3 description-details">Top Picks, for Fighting Games </p>
              <p className="mt-3 description-details">Looking to navigate the mean streets unscathed? It's no feat, in the realm of Street Fighting Games, where menacing gang leaders and rowdy thugs are ready to pummel you with their fists. Dive into your training regimen. Brace yourself for some thrilling combat!  </p>
            </div>
            <div className="mt-4">
              <ul>
                {game.map((game) => {
                  return (
                    <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                  );
                })}
              </ul>
            </div>
          </div> : !loading && game[0]?.category_id === "64538f357cdb7484b9b601a9" ?
            <div className="p-4 gamecategory-description">
              <div>
                <p className="mt-3 description-details">Check out this curated selection of strategy games that you can play right in your web browser. If you're a fan of the gameplay found in strategy, then these titles are a must see. AIGAMER offers a range of strategy games, including tower defense, real time strategy, role playing games turn based games, MMOs and classic board games.   </p>
              </div>
              <div>
                <p className="description-title mt-4">What is War Game? </p>
                <p className="mt-3 description-details">The evolution of the strategy video game genre traces back to the days of computers. While not as mainstream, as some genres today strategy games continue to captivate millions of players particularly those who enjoy War themed gaming experiences. </p>
                <p className="mt-3 description-details">Immerse Yourself in War Games which are web games </p>
                <p className="mt-3 description-details">Throughout history epic battles have shaped nations and civilizations alike. In War Games players are tasked with devising strategies and leading armies into battle to defeat their adversaries and emerge victorious.</p>
                <p className="mt-3 description-details">Indulge, in AIGAMER Medieval Gaming Experience</p>
                <p className="mt-3 description-details">Step in time, with our medieval themed games featuring castles, valiant knights and thrilling gladiator battles. Command your catapults to siege fortresses and assert your dominance as the ruler of the realm by expanding your conquests into neighboring kingdoms. </p>
              </div>
              <div className="mt-4">
                <ul>
                  {game.map((game) => {
                    return (
                      <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                    );
                  })}
                </ul>
              </div>
            </div> : !loading && game[0]?.category_id === "64538f257cdb7484b9b601a1" ?
              <div className="p-4 gamecategory-description">
                <div>
                  <p className="mt-3 description-details">Enjoy playing shooting games on aigamer.io to enhance your aiming skills and practice shooting with firearms such, as sniper rifles, handguns, assault rifles, revolvers and submachine guns like the Uzi. Explore a range of gun related games in the AIGAMER shooting category. </p>
                </div>
                <div>
                  <p className="description-title mt-4">What is Shooting Game?</p>
                  <p className="mt-3 description-details">Shooting games form a subset of action games that focus on using guns or projectiles. Over time shooters evolved to offer experiences in virtual worlds leading to the emergence of diverse subgenres that go beyond just shooting to include activities like driving vehicles. </p>
                  <p className="mt-3 description-details">Prepare for action in soldier themed games where you gear up for missions! Typically, shooters involve two elements; the ability to use weapons and some form of movement either by the player or interactive objects. The gameplay usually follows a predictable progression, with each round offering challenges</p>
                  <p className="mt-3 description-details">Engage in AIGamer Military Activities </p>
                  <p className="mt-3 description-details">Enlist, in the notch force globally protecting our borders against hostile intruders and airborne threats. Rifles, sniper rifles, handguns and explosives are all tools at your disposal to safeguard our nation. </p>
                </div>
                <div className="mt-4">
                  <ul>
                    {game.map((game) => {
                      return (
                        <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                      );
                    })}
                  </ul>
                </div>
              </div> : !loading && game[0]?.category_id === "64538edb7cdb7484b9b60175" ?
                <div className="p-4 gamecategory-description">
                  <div>
                    <p className="mt-3 description-details">Explore a selection of arcade and classic games, such as TallManRun, Metro Rush and Maze Escape. AIGAMER also offers a variety of retro games like Metro Rush and Counter Push. If you're a fan of arcade experiences, you'll find joy in these timeless games with histories. </p>
                  </div>
                  <div>
                    <p className="description-title mt-4">What is Arcade Game? </p>
                    <p className="mt-3 description-details">Arcade gaming stands as a genre in the world of video games shaping the way we enjoy this pastime. Classic and retro titles fall under this genre category. </p>
                    <p className="mt-3 description-details">Arcade Games: Timeless Classics and Vintage Hits </p>
                    <p className="mt-3 description-details">Enjoy AIGamer Selection of Platform Games </p>
                    <p className="mt-3 description-details">Platform games or 'platformers' are typically side scrolling adventures featuring terrains, with obstacles that need to be navigated. Action games gained popularity through platform games such as Super Mario Bros, contributing to the evolution of the gaming genre. </p>
                  </div>
                  <div className="mt-4">
                    <ul>
                      {game.map((game) => {
                        return (
                          <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                        );
                      })}
                    </ul>
                  </div>
                </div> : !loading && game[0]?.category_id === "64538e877cdb7484b9b60138" ?
                  <div className="p-4 gamecategory-description">
                    <div>
                      <p className="mt-3 description-details">Explore a variety of Skill games featuring throwing, mouse skills, clicking challenges, and baseball competitions. Test your abilities. Enhance your gaming prowess by engaging with AIGamer games that require practice to excel and elevate your gaming skills. These games are designed to strengthen your dexterity and prepare you for success in the world of esports.  </p>
                    </div>
                    <div>
                      <p className="description-title mt-4">What is a Skill Game? </p>
                      <p className="mt-3 description-details">Immerse yourself in video games that demand reflexes and instant decision making using your fingertips as extensions of your mind. React swiftly to paced challenges, skill-based puzzles and precise targets. Hone both your mental prowess at the premier sport gaming academy.  </p>
                      <p className="mt-3 description-details">Master: Mouse Skills </p>
                      <p className="mt-3 description-details">While many think of the keyboard as a gaming tool? Or could a gaming mouse hold power? A high-performance gaming mouse is indispensable for navigating skill-based obstacles like timing jumps perfectly or aiming with precision at targets or while soaring through landscapes. It serves as a piece of hardware for gameplay experiences.  </p>
                      <p className="mt-3 description-details">Skill Games: Simple to Start Challenging to Excel </p>
                      <p className="mt-3 description-details">Delve into an array of Skill games encompassing throwing tasks mouse skill challenges, clicking adventures, safe bubble popping fun and puzzle competitions.</p>
                      <p className="mt-3 description-details">Engage in AIGamer Mouse Skill Challenges </p>
                      <p className="mt-3 description-details">Equip yourself with a gaming mouse. Pave your path to triumph through clicks! Engage in Mouse Skill Games to enhance your reflexes and gaming abilities continually. </p>
                    </div>
                    <div className="mt-4">
                      <ul>
                        {game.map((game) => {
                          return (
                            <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                          );
                        })}
                      </ul>
                    </div>
                  </div> : !loading && game[0]?.category_id === "64538e7e7cdb7484b9b60130" ?
                    <div className="p-4 gamecategory-description">
                      <div>
                        <p className="mt-3 description-details">This section features all the driving and racing games, on AIGAMER. There are types of games within this category with driving games being among the forms of video games. Get ready for some high-speed action as you navigate through a variety of vehicles such as cars, trucks, boats, forklifts, motorcycles and more </p>
                      </div>
                      <div>
                        <p className="description-title mt-4">What is a Racing Game?  </p>
                        <p className="mt-3 description-details">Top Tip for Racing Games; Speed and Nitro to reach the finish line </p>
                        <p className="mt-3 description-details">Driving and racing video games are considered one of the most beloved game genres in history. The evolution of this genre started from arcade machines. Transitioned to consoles and PCs over time. While some may view these games as about driving cars or riding motorcycles, delving into their history reveals remarkable advancements and innovative driving elements being added gradually. </p>
                        <p className="mt-3 description-details">Games for Speed Enthusiasts; paced adventures await </p>
                        <p className="mt-3 description-details">AIGamer offers a range of racing games that cater to every taste. Whether you enjoy handling a quad bike, truck, bus, boat or even want to experience life as a taxi driver, simply launch a game. Immerse yourself in a driving experience, with full throttle action.  </p>
                        <p className="mt-3 description-details">Delve into an array of Skill games encompassing throwing tasks mouse skill challenges, clicking adventures, safe bubble popping fun and puzzle competitions.</p>
                        <p className="mt-3 description-details">Make sure your safety equipment is properly fastened. Jump on your motorcycle. I enjoy playing motorcycle games. Don't forget to refuel before firing up the engine on the AIGAMER site.  </p>
                      </div>
                      <div className="mt-4">
                        <ul>
                          {game.map((game) => {
                            return (
                              <li className="gamecategory-link"><Link to={`${ROUTES.PLAY_GAME.LIST}/${game._id}`}>{game.name}</Link></li>
                            );
                          })}
                        </ul>
                      </div>
                    </div> : ""
      } */}
    </div>
  );
};

export default Categorywise;
