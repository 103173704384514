import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { getAllSocialApi } from "../../../APIs/AdminAll_api";
import Game1 from "../../../assets/Images/User/Games/game1.png";
import CustomModal from "../../../Configs/CustomModal/CustomModal";
import DeleteSocial from "./DeleteSocial";
import Pagination from "../Pagination";

const Index = () => {
  const navigate = useNavigate();
  const [allSocial, setAllSocial] = useState([])
  const [Delete, setDelete] = useState(false)
  const [socialId, setSocialId] = useState('')
  const [loading, setLoading] = useState(true);


  const handleAddGame = () => {
    navigate(ROUTES.SOCIAL.ADD)
  }

  const getAllSocial = async () => {
    setLoading(true);
    const data = await getAllSocialApi();
    if (data.status === 200) {
      setLoading(false);
      setAllSocial(data.data.data)
    }
  }

  useEffect(() => {
    getAllSocial();
  }, [])

  const handleDelete = (index) => {
    setDelete(true);
    setSocialId(index);
  }

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Social Media Links</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content">
          <div className="pe-1 mb-xl-0">
            <Button type="button" className="btn add-btn" onClick={handleAddGame}>
              <i className="fa-regular fa-plus m-1"></i>
              Add Link
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive border-top userlist-table ">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3"
                >
                  <thead className="mb-3">
                    <tr className="mb-3 head-color">
                      <th>Name</th>
                      <th>Url</th>
                      <th style={{ width: "30px" }}>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  {!loading && allSocial.length > 0 && allSocial.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td className="text-capitalize">{item.name}</td>
                          <td>{item.url}</td>
                          <td>
                            <Link class="btn btn-sm btn-info btn-b" to={`${ROUTES.SOCIAL.UPDATE}/${item._id}`}>
                              <i class="las la-pen"></i>
                            </Link>
                            <Link class="btn btn-sm btn-danger" onClick={() => handleDelete(item._id)}>
                              <i class="las la-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })
                  }

                </Table>
                {
                  loading ?
                    <div className="text-center">
                      <div class="spinner-border m-2" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    : ""
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CustomModal display={Delete} handleClose={() => setDelete(false)} size='md' className='userModal'>
        <DeleteSocial setDelete={setDelete} socialId={socialId} getAllSocial={getAllSocial} />
      </CustomModal>

    </div>
  );
};

export default Index;
