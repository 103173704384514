import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { UserAllDataApi, UserChangeApi, UserChangeImg, UserChangeUserNameAPI } from '../APIs/UserAll_api';
import MainContext from './Context/MainContext';
import { Button, Form, Spinner } from "react-bootstrap"
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserIcon from "../assets/Images/download.jpg"
import { ENV_CONSTANTS } from '../Configs/Constants';
import ROUTES from '../Configs/Routes';

const IMG_PREFIX = ENV_CONSTANTS.REACT_APP_SERVER_URL

const UserEditProfile = () => {

  const context = useContext(MainContext);
  const { id } = useParams();
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")
  const [isloading, setLoading] = useState(false)


  const [image, setImage] = useState("")
  const [imagePath, setImagePath] = useState("");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    let fileName = file.name.toLowerCase();
    let indexs = [];

    for (let i = 0; i < fileName.length; i++) {
      if (fileName.indexOf('.', i) >= 0) {
        indexs.push(fileName.indexOf('.', i))
      }
    }

    let extention = fileName.slice(indexs.slice(-1)[0], fileName.length);

    if (extention.includes('.jpg') || extention.includes('.jpeg') || extention.includes('.png')) {
      setImage(file);
      if (file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImagePath(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      toast.error('Only images are allowed');
    }
  };

  const getAllData = async () => {
    const respData = await UserAllDataApi(id);
    if (respData.status === 200) {
      context.setAdminData(respData.data?.data);
    }
    else {
      toast.error(respData.response.data.message)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("gameUserToken");
    if (token) {
      getAllData();
    }
  }, [])

  const handleChange = async () => {
    const respData = await UserChangeApi()
    if (respData.status === 200) {
      setUserName(respData.data.data?.username)
      // toast.success(respData.data.message)
    }
    else {
      toast.error(respData.response.data.message)
    }
  }

  const EditProfile = async () => {
    const formData = {
      username: userName
    }
    const respData = await UserChangeUserNameAPI(formData)
    if (respData.status === 200) {
      setUserName(respData.data.data)
      navigate(ROUTES.USER)
      context.setAdmDataChange(!context.admDataChange)
    }
    else {
      toast.error(respData.response.data.message)
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', image)

    const respData = await UserChangeImg(formData)
    if (respData.status === 200) {
      setImage(respData.data.data)
      context.setAdmDataChange(!context.admDataChange)
    }
    else {
      toast.error(respData.response.data.message)
    }

  }


  return (
    <div>
      <div class="offcanvas offcanvas-end rightCanvas" tabindex="-1" id="offcanvasRight3">
        <div class="offcanvas-header">
          <div type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"><i class="fa-solid fa-angle-left"></i></div>
          <div class="offcanvas-header">
            <p></p>
            <div type="button" data-bs-dismiss="offcanvas">
              <span className="closeIcon"><i class="fa-solid fa-xmark"></i></span>
            </div>
          </div>
        </div>

        <div style={{ padding: "10px 50px" }}>

          {/* <div className="main-profile-overview text-center position-relative">
            <div className="main-img-user profile-user">
              <img src={context.adminData.image ? imagePath : image ? ENV_CONSTANTS.API_SERVER_URL + image : UserIcon} alt="" />
              <Button className="profile-edit">
                <i className="fas fa-camera" />
                <Form.Control type="file" accept=".png, .jpg, .jpeg" name="image" onChange={handleFileChange} />
              </Button>
            </div>
          </div> */}

          <div className='avatarPreview main-profile-overview text-center position-relative'>
            <div className="main-img-user profile-user">
              <img src={imagePath ? imagePath : image ? IMG_PREFIX + image : UserIcon} alt='Admin' className='w-100' crossOrigin='anonymous' />
              <Button className="profile-edit">
                <i className="fas fa-camera" />
                <Form.Control type="file" accept=".png, .jpg, .jpeg" name="image" onChange={handleFileChange} />
              </Button>
            </div>
          </div>

          <div className="edit-input mt-4">
            <div className="edit-mail">
              <span className="me-2">
                <i className="fa-regular fa-user"></i>
              </span>
              <span className="enter">UserName :- </span>
            </div>
            <div className="p-2 mt-2 d-flex justify-content-between edit-input-value">
              <p className="">{userName ? userName : context.adminData?.username}</p>
              <p>
                <Link className="text-decoration-none editUser" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight4">Edit</Link>
              </p>
            </div>
          </div>

          {/* <div className='edit-input mt-4'>
            <div className="edit-mail">
              <span className="me-2">
                <i className="fa-regular fa-user"></i>
              </span>
              <span className="enter">Location :- </span>
            </div>
            <div className="p-2 mt-2 d-flex justify-content-between edit-input-value">
              <p className="">{context.adminData?.location}</p>
            </div>
          </div> */}

          <div className='confirm mt-5' onClick={handleSubmit}>
            <button className='text-decoration-none text-light w-100' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight">Submit</button>
          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end rightCanvas" tabindex="-1" id="offcanvasRight4" style={{ padding: "12px 15px" }}>
        <div class="offcanvas-header">
          <div type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"><i class="fa-solid fa-angle-left"></i></div>
          <div class="offcanvas-header">
            <p className='text-center'>Change Username</p>
          </div>
          <div type="button" data-bs-dismiss="offcanvas">
            <span className="closeIcon"><i class="fa-solid fa-xmark"></i></span>
          </div>
        </div>
        <div className='mt-4 mainChange'>
          <div className='text-center'>
            <span className="change-user">{userName ? userName : context.adminData?.username}</span>
          </div>
          <div className='d-flex changeUser text-center mt-3' onClick={handleChange}>
            {/* <Link className='me-2 text-decoration-none text-light fw-bold'>Change</Link> */}
            <Link type="submit" className='me-2 text-decoration-none text-light fw-bold'>
              {isloading ?
                <div className='text-center'>
                  <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                :
                'Change'
              }
            </Link>
          </div>
        </div>
        <div className='mt-4 confirm'>
          {/* <Link className='text-decoration-none text-light' type='submit' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" onClick={EditProfile}>Confirm Change</Link> */}
          <Link type="submit" className='me-2 text-decoration-none text-light fw-bold' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" onClick={EditProfile}>
            {isloading ?
              <div className='text-center'>
                <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
              :
              'Confirm Change'
            }
          </Link>

        </div>
      </div>

    </div>
  )
}

export default UserEditProfile
