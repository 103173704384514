import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { deteleGameApi, zipUpload } from "../../../APIs/AdminAll_api";
import { toast } from 'react-toastify';
import ROUTES from "../../../Configs/Routes";


const UploadZipModal = ({ setShow, gameId, getAllGame, zipName }) => {
	const [loading, setLoading] = useState(false)
	const [uploadZip, setUploadZip] = useState("");

	const navigate = useNavigate()

	const handleZipupload = async () => {
		const uploadZipExt = uploadZip.name?.split(".").pop();

		if (
			uploadZip?.name &&
			uploadZipExt !== "zip" &&
			uploadZipExt !== "rar" &&
			uploadZipExt !== "7zip" &&
			uploadZipExt !== "rar4"
		) {
			setLoading(false);
			toast.error("Please upload valid zip file");
		} else {
			const formData = new FormData();
			if (uploadZip?.name) {
				formData.append("webgl_zip", uploadZip);
			}
			setLoading(true);
			const data = await zipUpload(formData, gameId);
			if (data.status === 200) {
				setShow(false)
				setLoading(false);
				navigate(ROUTES.GAME.LIST);
				getAllGame()
				toast.success("Zip uploaded successfully");
			} else {
				setLoading(false);
				toast.error("Something went wrong");
			}
		}
	}

	return (
		<div>
			<Modal.Header closeButton>
				<Modal.Title>Upload Zip</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group controlId="formFile" className="mb-3">
					<Form.Label>
						Upload Game Zip
					</Form.Label>
					<Form.Control
						type="file"
						onChange={(e) => {
							setUploadZip(e.target.files[0]);
						}}
						accept=".zip,.rar,.7zip,.rar4"
						required
					/>
				</Form.Group>
				{zipName && !uploadZip?.name && (
					<Form.Label className="get-lebal">
						{zipName}
						{/* {uploadZipLoading ? (
							<div
								class="spinner-border spinner-border-sm"
								role="status"
							>
								<span class="sr-only">Loading...</span>
							</div>
						) : (
							<i
								class="fa-solid fa-circle-xmark ms-2"
								onClick={() => {
									handleDelete("webgl");
								}}
							></i>
						)} */}
					</Form.Label>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => { setShow(false) }}>
					Close
				</Button>
				<Button variant="primary" onClick={() => handleZipupload()}>
					{loading ? <div
						class="spinner-border spinner-border-sm me-2"
						role="status"
					>
						<span class="visually-hidden">Loading...</span>
					</div> : "Save Changes"}
				</Button>
			</Modal.Footer>
		</div >
	)
}

export default UploadZipModal