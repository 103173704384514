import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Col,
  Row,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import { createBannerApi } from "../../../APIs/AdminAll_api";
import { getAllcategoryApi } from "../../../APIs/UserAll_api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";

const AddGame = () => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [video_file, setVideo_File] = useState("");
  const [status, setStatus] = useState(true);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;
    const imageExt = image.name?.split(".").pop();
    const videoExt = video_file.name?.split(".").pop();

    if (!video_file && !image) {
      setLoading(false);
      toast.error("Please enter image or video");
    } else if (re1.test(name)) {
      setLoading(false);
      toast.error("Please enter valid game name");
    } else if (name.trim() === "" || !re.test(name)) {
      setLoading(false);
      toast.error("Please enter valid game name");
    } else if (
      image &&
      imageExt !== "jpg" &&
      imageExt !== "jpeg" &&
      imageExt !== "png" &&
      imageExt !== "svg"
    ) {
      setLoading(false);
      toast.error("Please upload valid image");
    } else if (
      video_file &&
      videoExt !== "mp4" &&
      videoExt !== "mkv" &&
      videoExt !== "avi" &&
      videoExt !== "mov" &&
      videoExt !== "wmv"
    ) {
      setLoading(false);
      toast.error("Please upload valid video file");
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      formData.append("url", url);
      formData.append("status", status);
      formData.append("video_file", video_file);

      setLoading(true);
      const data = await createBannerApi(formData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.BANNER.LIST);
        toast.success("Game Added Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Banner</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Add Banner</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={6}>
          <Card className="box-shadow-0">
            <Card.Body>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name<label className="text-danger">*</label>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    id="inputEmail"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Url"
                    id="inputEmail"
                    name="url"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Game Image</Form.Label> (390*200)
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Video</Form.Label> (390*200)
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setVideo_File(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                    Status
                  </Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Check
                      type="radio"
                      label="Active"
                      name="status"
                      value="true"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Inactive"
                      name="status"
                      value="false"
                      checked={!status}
                      onChange={handleStatusChange}
                    />
                  </div>
                </Form.Group>
                <div>
                  <Button type="submit" className="editBtn">
                    {loading ? (
                      <div
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                    Submit
                  </Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => {
                      navigate(ROUTES.BANNER.LIST);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddGame;
