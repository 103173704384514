import React from "react";

export const CoociePolicy = () => {
  return (
    <div className="aboutDiv">
      <div className="my-5">
        <h2 className="aboutMainTitle text-uppercase">Cookie Policy</h2>
        <p className="aboutMainP">Effective Date: 5th May, 2023</p>
      </div>

      <div>
        <ul className="privacy-main">
          <li>Introduction</li>
          <p>
            Welcome to AiGamer.io! This Cookie Policy explains how we use
            cookies and similar tracking technologies on our web platform,
            AiGamer.io.
          </p>
          <li className="mt-4">What Are Cookies?</li>
          <p>
            Cookies are small text files that are stored on your device when you
            visit our platform. They enable us to recognize your device and
            enhance your browsing experience.
          </p>
          <li className="mt-4">
            How We Use Cookies We use cookies for various purposes, including:
          </li>
          <ul className="privacy-child">
            <li>
              Authentication: To identify you when you log in to our platform.
            </li>
            <li>Preferences: To remember your preferences and settings.</li>
            <li>
              Analytics: To collect information about how you interact with our
              platform and improve its performance.
            </li>
            <li>
              Advertising: To deliver targeted advertisements based on your
              interests.
            </li>
          </ul>
          <li className="mt-4">Types of Cookies We Use </li>
          <p>
            a) Essential Cookies: These cookies are necessary for the
            functioning of our platform and cannot be disabled.
            <br /> b) Performance Cookies: These cookies collect information
            about how you use our platform and help us improve its performance.
            <br />
            c) Functionality Cookies: These cookies remember your preferences
            and settings to enhance your browsing experience.
            <br /> d) Targeting Cookies: These cookies track your browsing
            habits and enable us to deliver targeted advertisements.
          </p>
          <li className="mt-4">Managing Cookies</li>
          <p>
            You can manage or disable cookies through your browser settings.
            However, please note that certain features of our platform may not
            function properly if you disable certain cookies.
          </p>
          <li className="mt-4">Third-Party Cookies</li>
          <p>
            We may use third-party services that place cookies on our platform.
            These cookies are subject to the respective privacy policies of the
            third-party providers.
          </p>
          <li className="mt-4">Social Media Features </li>
          <p>
            Our platform may include social media features, such as sharing
            buttons or widgets. These features may collect your IP address and
            the page you are visiting on our platform and may set a cookie to
            enable the feature to function properly. Social media features are
            governed by the privacy policies of the respective social media
            platforms.
          </p>
          <li className="mt-4">Changes to Our Cookie Policy</li>
          <p>
            We reserve the right to update or modify this Cookie Policy at any
            time. Any changes will be effective upon posting on our platform.
          </p>
          <li className="mt-4">Analytics and Advertising </li>
          <p>
            Cookies We may use third-party analytics and advertising services
            that use cookies and similar tracking technologies to collect
            information about your usage patterns and preferences. These cookies
            may be used to deliver targeted advertisements or analyse user
            behaviour. You can manage your cookie preferences by adjusting your
            browser settings or through the opt-out mechanisms provided by the
            relevant third-party service providers.
          </p>
          <li className="mt-4">Changes to the Privacy Policy</li>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective upon posting on our platform.
          </p>
          <li className="mt-4">Contact Information</li>
          <p>
            For any questions or concerns regarding our Cookie Policy, please
            contact us at [contact information].
          </p>
        </ul>
      </div>
    </div>
  );
};
