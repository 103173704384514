import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import { TrackGame, getAllGameApi } from "../../APIs/UserAll_api";
import OwlCarousel from "react-owl-carousel";
import MainContext from "../../Components/Context/MainContext";
import { searchGameApi, allBannerGet } from "../../APIs/UserAll_api";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toast } from "react-toastify";
import ReactGA from "react-ga"


export const Home = () => {
  const [game, setGame] = useState([]);
  const [banner, setBanner] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const data = useContext(MainContext);

  useEffect(() => {
    let gameGrids = document.getElementsByClassName("gameGridDiv");
    if (gameGrids.length > 0) {
      gameGrids[0]?.classList.add("big");
      gameGrids[11]?.classList.add("big");
    }
  }, [game]);

  const getAllGame = async () => {
    try {
      setLoading(true);
      const res = await getAllGameApi();
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data.search) {
      getAllGame();
    }
  }, [data.search]);

  const getSearchResult = async () => {
    try {
      const res = await searchGameApi(data.search);
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data.search) {
      getSearchResult();
    }
  }, [data.search]);

  const bannerItems = {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    992: {
      items: 2,
    },
    1024: {
      items: 4,
    },
  };

  const getBanner = async () => {
    try {
      const res = await allBannerGet();
      if (res.status === 200) {
        setBanner(res.data.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const handleHover = (e) => {
    e.target.play();
  };

  const handleHoverOut = (e) => {
    e.target.currentTime = 0;
    e.target.pause();
  };

  const handleGame = async (game) => {
    data.setGameName(game.name)
    const decodedParam = decodeURIComponent(game.name);
    const mergedParam = decodedParam.split(' ').join('').toLowerCase();

    navigate(`${ROUTES.PLAY_GAME.LIST}/${mergedParam}`, { state: { game_id: game._id } })

    ReactGA.event({
      category: game.category_id.name,
      action: "play game",
      label: "home play game",
      value: game.name
    })

    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game._id
      }
      await TrackGame(body)
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])



  return (
    <div className="">
      {banner.length > 0 && (
        <>
          <div className="game-title mb-3">Recommended for you</div>
          <OwlCarousel
            className="bannerCarousel owl-theme"
            responsive={bannerItems}
            margin={20}
            dots={false}
            // loop
            nav
          >
            {banner &&
              banner.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <div
                      className="Carousel-main"
                      onClick={() => {
                        navigate(item.url);
                      }}
                    >
                      {item.video_file && (
                        <video
                          onMouseEnter={(e) => { game.image && handleHover(e) }}
                          onMouseLeave={(e) => { game.image && handleHoverOut(e) }}
                          className={item.image ? "homeVideo" : ""}
                          src={item.video_file}
                          muted
                          loop
                          autoPlay
                        ></video>
                      )}
                      {item.image && (
                        <img
                          src={item.image}
                          alt="image"
                          className={
                            item.video_file
                              ? "homeimage bannerImage"
                              : "bannerImage"
                          }
                        />
                      )}
                      <span className="gameName">{item.name}</span>
                    </div>
                  </div>
                );
              })}
          </OwlCarousel>
        </>
      )}

      {loading ? (
        <div className="text-center">
          <div
            class="spinner-border text-white"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="game-title mb-3 mt-3">Games</div>
      <div className="homeGamesDiv">
        {!loading &&
          game.length > 0 &&
          game?.map((game, index) => {
            return (
              game.is_zip_upload && (
                <div
                  className="gameGridDiv"
                  key={index}
                  // onClick={() => {
                  //   navigate(`${ROUTES.PLAY_GAME.LIST}/${game._id}`);
                  // }}
                  onClick={() => handleGame(game)}
                >
                  {game.video_file && (
                    <video
                      onMouseEnter={(e) => handleHover(e)}
                      onMouseLeave={(e) => handleHoverOut(e)}
                      className="homeVideo"
                      src={game.video_file}
                      muted
                      loop
                      onClick={() => {
                        navigate(`${ROUTES.PLAY_GAME.LIST}/${game._id}`, { state: { name_id: game._id } });
                      }}
                      autoPlay
                    ></video>
                  )}
                  <img
                    src={game.image}
                    alt="image"
                    className={game.video_file ? "homeimage" : ""}
                  />
                  <span className="gameName">{game.name}</span>
                </div>
              )
            );
          })}
      </div>

      <div className="p-4 hone-description">
        <div>
          <p className="description-title">Explore AIGamer for Free Online Games!”  </p>
          <p className="mt-3 description-details">Welcome to AIGAMERS, where your favorite game genres come to life! Explore our website to discover the latest additions, popular picks, and community favorites. </p>
          <p className="mt-3 description-details">Whether you’re into car games, intense shooting, thrilling racing, strategic challenges, or engaging multiplayer experiences, we’ve got it all. </p>
          <p className="mt-3 description-details">Our top categories are just a click away, making it easy to find the entertainment you crave. And don’t forget to check out related recommendations below each game – your next gaming adventure awaits!” 🎮🔥 </p>
        </div>
        <div>
          <p className="description-title mt-4">Exciting Free Online Games for Kids! </p>
          <p className="mt-3 description-details">At AIGAMERS, we’ve curated an incredible selection of free online games designed specifically for gaming enthusiasts. </p>
          <p className="mt-3 description-details">Dive into our daily-updated content and experience thrilling adventures across various genres. Whether you’re a fan of mind games, intense War games, adrenaline-pumping car and monster truck races, or heart-pounding first-person shooter challenges, we’ve got you covered. </p>
          <p className="mt-3 description-details">And the best part is - No downloads are required! Simply visit our website, fire up your browser, and immerse yourself in a world of gaming excitement.”  </p>
        </div>
        <div>
          <p className="description-title mt-4">Enjoy Instant Play Free Online Games Without Any Downloads!” 🎮🚀</p>
          <p className="mt-3 description-details">When you dive into the world of AIGAMERS, there’s no need to fuss with downloads. Our games are accessible directly in your browser window, whether you’re on a laptop, PC, or mobile device. </p>
          <p className="mt-3 description-details">For those who crave more immersive experiences, our expansive skill and Social Games allow you to register for free, create your own in-game account, or sign in via social media to connect with friends – all without any file downloads.   </p>
          <p className="mt-3 description-details">We prioritize safety and user-friendliness, especially for children and teens. So go ahead, enjoy the vast array of online gameplay and gaming content at AIGAMERS, and it won’t cost you a dime! 🎮🌟 </p>
        </div>
      </div>

    </div>
  );
};
