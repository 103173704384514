import React, { useState } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import { createCategoryApi } from "../../../APIs/AdminAll_api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../../Configs/Routes";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddGame = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [status, setStatus] = useState(true);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [Description, setDescription] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;

    if (re1.test(name)) {
      setLoading(false);
      toast.error("Please enter valid category name");
    } else if (name.trim() === "" || !re.test(name)) {
      setLoading(false);
      toast.error("Please enter valid category name");
    } else {

      const formData = new FormData();
      formData.append("name", name);
      formData.append("status", status);
      formData.append("image", image);
      formData.append("description", Description)
      setLoading(true);
      const data = await createCategoryApi(formData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.CATEGORY.LIST);
      }
      if (data.response.data.status === 422) {
        setLoading(false);
        toast.error(data.response.data.message);
      }
    }
  };


  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Category</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Add Category</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={6}>
          <Card className="box-shadow-0">
            <Card.Body>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    id="inputEmail"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    Upload Category Image<label className="text-danger">*</label>(18*18)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                    Status
                  </Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Check
                      type="radio"
                      label="Active"
                      name="status"
                      value="true"
                      checked={status}
                      onChange={handleStatusChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Inactive"
                      name="status"
                      value="false"
                      checked={!status}
                      onChange={handleStatusChange}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <ReactQuill theme="snow" value={Description} onChange={setDescription} />
                </Form.Group>
                <div>
                  <Button type="submit" className="editBtn">
                    {" "}
                    {loading ? (
                      <div
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                    Submit
                  </Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => {
                      navigate(ROUTES.CATEGORY.LIST);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddGame;
