import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { deteleGameApi } from "../../../APIs/AdminAll_api";
import { toast } from 'react-toastify';

const DeleteGame = ({ setDelete, gameId, getAllGame }) => {

  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    const data = await deteleGameApi(gameId);
    if (data.status === 200) {
      setLoading(false)
      setDelete(false)
      setLoading(false)
      getAllGame()
      toast.success("Game Deleted Successfully")
    } else {
      setLoading(false)
    }
  }
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title><h5 className="confirmTitle">Confirmation Alert!</h5></Modal.Title>
      </Modal.Header>
      <Form className='adminForm' onSubmit={handleDelete}>
        <Modal.Body>
          <p className='mb-2'><span>Are you sure to remove this game?</span></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='modalNo' onClick={() => { setDelete(false) }}>No</Button>
          <Button variant="primary" className='modalYes' onClick={() => handleDelete()}>{loading ? <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> : "Yes"}</Button>
        </Modal.Footer>
      </Form>
    </div>
  )
}

export default DeleteGame