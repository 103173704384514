import React, { useState } from "react";
import { Button, Col, Row, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import ForgotImg from "../../../assets/Images/media/forgot.png";
import Favicon from "../../../assets/Images/brand/favicon.png";

export default function ForgotPassword() {
  return (
    <>
      <div className="page">
        <div className="container-fluid">
          <Row className="no-gutter">
            <Col md={7} className="contain-col">
              <Row className="img-row">
                <Col lg={12} className="img-col my-auto mx-auto">
                  <img
                    src={ForgotImg}
                    alt="forgot"
                    className="login-img my-auto mx-auto"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6} xl={5} lg={6} className="content-div">
              <div className="login">
                <div className="container">
                  <Row>
                    <Col md={11} className="mx-auto">
                      <div className="card-sigin">
                        <div className="mb-5 card-div">
                          <img src={Favicon} className="sign-favicon"></img>
                          <h1 className="main-logo1 ms-1 me-0 my-auto">
                            aigamer Games
                          </h1>
                        </div>
                      </div>
                      <div className="card-sigin">
                        <div className="main-signup-header">
                          <h2>Forgot Password!</h2>
                          <h5 className="fw-semibold mb-4">
                            Please Enter Your Email
                          </h5>
                          <Form>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                className="pass-input"
                                placeholder="Enter your Email"
                                type="email"
                                required
                              ></Form.Control>
                            </Form.Group>
                            <Button type="submit" className="signin-btn mt-3">
                              Send
                            </Button>
                          </Form>
                          <div className="main-signin-footer mt-3">
                            <p>
                              Forget it,
                              <Link to={ROUTES.USER} className="createAccount">
                                {" "}
                                Send me back
                              </Link>{" "}
                              to the sign in screen.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
