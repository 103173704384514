import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSocialApi } from "../../APIs/UserAll_api";
import { useEffect } from "react";
import ROUTES from "../../Configs/Routes";
import { all } from "axios";

const Footer = () => {
  const [social, setSocial] = useState([]);
  const [cookie, setCookie] = useState(false);

  const getAllSocial = async () => {
    const data = await getSocialApi();
    if (data.status === 200) {
      setSocial(data.data.data);
    }
  };

  useEffect(() => {
    getAllSocial();
  }, []);

  const handleAcceptCookies = () => {
    const today = new Date();
    const date = today.getDate();
    localStorage.setItem("session", date);
    setCookie(true);
  };

  useEffect(() => {
    const today = new Date();
    const date = today.getDate();
    if (localStorage.getItem("session") == date) {
      setCookie(true);
    } else {
      setCookie(false);
      localStorage.removeItem("session");
    }
  }, [cookie]);





  return (
    <div>
      <div className="container-fluid footer py-1">
        <div className="text-white d-md-flex flex-wrap text-center">
          <div className="my-2">
            Copyright © 2023 aigamer.io. All rights reserved.
          </div>
          <div className="ms-sm-auto footerNav me-5 my-2">
            <Link to={ROUTES.ABOUT}>About Us</Link>
            <Link to={ROUTES.USER_PRIVACY}>Privacy Policy</Link>
            <Link to={ROUTES.TERMS_CONDITION}>Terms & Conditions</Link>
            <Link to={ROUTES.COOKIE_POLICY}>Cookie Policy</Link>
            <Link to={ROUTES.DISCLAIMER_POLICY}>Disclaimer Policy</Link>
            <Link to={ROUTES.CONTACT}>Contact Us</Link>
          </div>
          <div className="my-2">
            {social.map((item) => {
              return item.name == "facebook" ? (
                <a className="footerLink mx-1" href={item.url} target="_blank">
                  <span>
                    <i class="fa-brands fa-square-facebook"></i>
                  </span>
                </a>
              ) : item.name == "twitter" ? (
                <a className="footerLink mx-1" href={item.url} target="_blank">
                  <span>
                    <i class="fa-brands fa-twitter"></i>
                  </span>
                </a>
              ) : item.name == "youtube" ? (
                <a className="footerLink mx-1" href={item.url} target="_blank">
                  <span>
                    <i className="fa-brands fa-youtube"></i>
                  </span>
                </a>
              ) : item.name == "linkdin" ? (
                <a className="footerLink mx-1" href={item.url} target="_blank">
                  <span>
                    <i className="fa-brands fa-linkedin"></i>
                  </span>
                </a>
              ) : item.name == "instagram" ? (
                <a className="footerLink mx-1" href={item.url} target="_blank">
                  <span>
                    <i className="fa-brands fa-instagram"></i>
                  </span>
                </a>
              ) : null;
            })}
          </div>
        </div>
      </div>
      {cookie === false && (
        <div className="position-fixed cookie-main">
          <p>
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            are happy with it.
          </p>
          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn btn-primary me-3"
              onClick={handleAcceptCookies}
            >
              Accept Cookies
            </button>
            <button className="btn btn-secondary" onClick={handleAcceptCookies}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
