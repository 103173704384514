import React, { useState } from "react";
import MainContext from "./MainContext";

export default function ContextState(props) {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(false);
  const [search, setSearch] = useState("");
  const [adminSidebar, setAdminSidebar] = useState(false);
  const [userData, setUserData] = useState(false);
  const [signupData, setSignupData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [admDataChange, setAdmDataChange] = useState(false);
  const [adminData, setAdminData] = useState(false);
  const [depositStatus, setDepositStatus] = useState(false);
  const [gameName, setGameName] = useState('')
  const [caregoryName, setCategoryName] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')


  const value = {
    sidebar, setSidebar,
    rightSidebar, setRightSidebar,
    search, setSearch,
    adminSidebar, setAdminSidebar,
    userData, setUserData,
    signupData, setSignupData,
    admDataChange, setAdmDataChange,
    adminData, setAdminData,
    allData, setAllData,
    depositStatus, setDepositStatus,
    gameName, setGameName,
    caregoryName, setCategoryName,
    categoryDescription, setCategoryDescription,
  };

  return (
    <MainContext.Provider value={value}>{props.children}</MainContext.Provider>
  );
}
