import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { getAllUserContactApi } from "../../../APIs/AdminAll_api";
import Pagination from "../Pagination";
import moment from "moment";

export const UserConatct = () => {
  const [allContact, setAllContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecord, setTotalRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");

  const getAllContact = async () => {
    setLoading(true);
    const data = await getAllUserContactApi(currentPage);
    if (data.status === 200) {
      setLoading(false);
      setAllContact(data.data.data);
      setTotalRecord(data?.data.total_record);
    }
  };

  useEffect(() => {
    getAllContact();
  }, [currentPage]);

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / 10;
      setTotalPage(page);
    }
  }, [totalRecord]);

  return (
    <div>
      <div className="container-fluid">
        <div className="dashboard-header">
          <div className="my-auto">
            <div className="d-flex">
              <h4 className="content-title mb-0 my-auto">User Contact</h4>
            </div>
          </div>
        </div>
        <Row className="row-sm">
          <Col md={12}>
            <Card>
              <Card.Body>
                <div className="table-responsive border-top userlist-table ">
                  <Table
                    responsive
                    className="table card-table table-hover table-vcenter text-nowrap mb-3"
                  >
                    <thead className="mb-3">
                      <tr className="mb-3 head-color">
                        <th>name</th>
                        <th>Email</th>
                        <th>Subject</th>
                        <th>
                          <span>Message</span>
                        </th>
                        <th>create at</th>
                      </tr>
                    </thead>
                    {!loading &&
                      allContact.length > 0 &&
                      allContact.map((item, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>{item.full_name}</td>
                              <td>{item.email}</td>
                              <td className="text-wrap">{item.subject}</td>
                              <td className="text-wrap">{item.message}</td>
                              <td>
                                {moment(item.createdAt).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </Table>
                  {loading ? (
                    <div className="text-center">
                      <div
                        class="spinner-border m-2"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {totalPage >= 1 && (
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};
