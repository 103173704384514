import React, { useState, useEffect } from 'react'
import { Table, Button, Col, Row, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { GetUserLoginDetails } from "../../../APIs/AdminAll_api";
import Pagination from "../Pagination";
import moment from 'moment';

function LoginHistory() {
	const { id } = useParams()

	const [loading, setLoading] = useState(true);
	const [LoginHiostoryList, setLoginHiostoryList] = useState([])
	const [totalRecord, setTotalRecord] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState("");


	const getAllCategory = async () => {
		setLoading(true);
		const data = await GetUserLoginDetails(id);
		if (data.status === 200) {
			setLoading(false);
			setTotalRecord(data?.data.total_record);
			setLoginHiostoryList(data.data.data);
		}
	};

	useEffect(() => {
		getAllCategory();
	}, [currentPage]);

	useEffect(() => {
		if (totalRecord || totalRecord === 0) {
			var page = totalRecord / 10;
			setTotalPage(page);
		}
	}, [totalRecord]);

	return (
		<div className="container-fluid">
			<div className="dashboard-header">
				<div className="my-auto">
					<div className="d-flex">
						<h4 className="content-title mb-0 my-auto">Login History</h4>
					</div>
				</div>
			</div>
			<Row className="row-sm">
				<Col md={12}>
					<Card>
						<Card.Body>
							<div className="table-responsive border-top userlist-table ">
								<Table
									responsive
									className="table card-table table-hover table-vcenter text-nowrap mb-3"
								>
									<thead className="mb-3">
										<tr className="mb-3 head-color">
											<th>Login at</th>
											<th>IP Address</th>
											<th>Browser|OS</th>
										</tr>
									</thead>
									{!loading &&
										LoginHiostoryList.map((item, index) => {
											let timeAgo = moment(item?.createdAt).fromNow()
											let formattedTime = moment(item?.createdAt).format('DD-MM-YYYY HH:mm A');

											return (
												<tbody>
													<tr>
														<td>
															{formattedTime}<br />
															{timeAgo}
														</td>
														<td>
															{item.ip_address}
														</td>
														<td>
															{item.browser ? item.browser : '-'}
														</td>
													</tr>
												</tbody>
											);
										})}
								</Table>
								{
									!loading && LoginHiostoryList.length === 0 && <div className="text-center">
										Data not found
									</div>
								}
								{loading ? (
									<div className="text-center">
										<div
											class="spinner-border m-2"
											style={{ width: "3rem", height: "3rem" }}
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{totalPage >= 1 && (
				<Pagination
					totalPage={totalPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</div>
	)
}

export default LoginHistory