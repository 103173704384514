import React, { useContext } from 'react'
import { Button, FormGroup, InputGroup, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { Input, Label, Spinner } from 'reactstrap'
import ROUTES from '../Configs/Routes'
import AiGamer from "../assets/Images/User/AdminLogo.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react';
import { UserSignupApi } from '../APIs/UserAll_api';
import { toast } from 'react-toastify';
import MainContext from './Context/MainContext';
// import FacebookLogin from 'react-facebook-login';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const UserSignup = () => {

  const context = useContext(MainContext);
  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [countryCode, setCountryCode] = useState('');

  const handlePhoneChange = (value, country) => {
    setCountryCode(country.dialCode);
    setPhone(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phone === "") {
      toast.error("Please enter your phone number")
      setLoading(false);
    } else {
      const data = {
        email: email,
        countryCode: countryCode,
        phone: phone,
      };
      setLoading(true)
      const respData = await UserSignupApi(data);
      if (respData.status === 200) {
        navigate(ROUTES.AUTH_OTP);
        context.setSignupData(data)
        toast.success(respData.data?.message).
          setLoading(false)
      }
      else {
        toast.error(respData.response.data?.message)
        setLoading(false)
      }
    }
  };


  const handleFacebookLogin = (res) => {
    const data = {
      socialId: res.id,
      socialType: "facebook"
    }
    axios({
      method: 'post',
      url: `${BASE_URL}api/v1/users/socialLogin`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          navigate(ROUTES.USER);
          // context.setSignupData(data)
          toast.success(response.data?.message)
          localStorage.setItem('gameUserToken', response.data.token);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        // if (error.response.status === 422) {
        //   toast.error(error.response.data.message);
        //   popup.setLoginModal(true);
        // }

        // else if (error.response.status === 401) {
        //   toast.error(error.response.data.message);
        //   popup.setLoginModal(true);
        // }
        // else {
        //   toast.error("Something went wrong!");
        //   popup.setLoginModal(true);
        // }
      }
      )
  }
  return (
    <>
      <div className='login-page'>
        <div className='main'>
          <div className="login-container">
            <div className="sign-in" style={{ padding: "32px" }}>
              <div className='arrow'>
                <Link to={ROUTES.USER}>
                  <i class="fa-solid fa-arrow-left"></i>
                </Link>
                <img src={AiGamer} alt='' className="user-aigamer me-5 "></img>
              </div>

              <div className='text-dark mt-2'>
                <p>Sign up with AiGamer</p>
              </div>

              <Form className='mt-2' onSubmit={handleSubmit}>
                <FormGroup className="" style={{ textAlign: "initial" }}>
                  <Label for="exampleEmail">
                    Enter Your email address :
                  </Label>
                  <Input
                    className='lable-input'
                    id="exampleEmail"
                    placeholder="Email address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>

                <FormGroup className='mt-3'>
                  <PhoneInput
                    className='signupMobile'
                    placeholder='+91 12345-67890'
                    country="in"
                    value={phone}
                    onChange={handlePhoneChange}
                    enableSearch
                    required
                  />
                </FormGroup>

                <div className="mt-4">
                  <Button type="submit" className="w-100 signupSubmitBtn">
                    {loading ?
                      <div className='text-center'>
                        <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                      :
                      'Submit'
                    }
                  </Button>
                </div>
                <p className="signUp-link mt-4">Already have an Account? <Link to={ROUTES.AUTH_LOGIN}>Sign In</Link></p>

                {/* <div className='text-dark mt-4'>
                  <p>Sign up with</p>
                </div> */}

                {/* <div className='mt-3'>

                  <LoginSocialFacebook
                    appId="6616336031735019"
                    cookie="true"
                    xfbml="true"
                    scope="public_profile"
                    onResolve={(response) => {
                      handleFacebookLogin(response.data)
                    }}
                    onReject={(err) => {
                    }}
                    fields={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                  >
                    <span className='facebook-icon' ><i className="fa-brands fa-facebook-f" /></span>
                  </LoginSocialFacebook>
                </div> */}

              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserSignup
