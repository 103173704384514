import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import "./assets/Css/app.css";
import "./assets/Css/style.css";
import "./assets/Css/responsive.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
