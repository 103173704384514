import { REQUEST_METHODS } from "../Configs/RequestMethods";
import { apiHandlerOfUserAPI, apiHandlerWithFromData, apiHandlerWithUserFormData, apiHandlerWithUserFromData } from "../Utils/ApiHandler";

export const getAllGameApi = async () => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      "api/v1/users/games",
      {}
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getAllcategoryApi = async () => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      "api/v1/users/categories",
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCategoryWiseDataApi = async (id) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/games/category/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSingleGameApi = async (id) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/games/game/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getWithoutRunningGameApi = async (id) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/games/getgame/${id}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getSocialApi = async () => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/socialmedia`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const contactApi = async (data) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/contact/store`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const searchGameApi = async (data) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/games/search?name=${data}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const allBannerGet = async () => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.GET,
      `api/v1/users/banner`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const LikeApi = async (body) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/like/store`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disLikeApi = async (body) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/dislike/store`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};


export const UserLoginApi = async (data) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/login`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const OtplessApi = async (data) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/otpless`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserSignupApi = async (data) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/signup`,
      data,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserEmailOtpVerify = async (formData) => {
  try {
    const response = await apiHandlerOfUserAPI(
      REQUEST_METHODS.POST,
      `api/v1/users/verifyOTP`,
      formData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserAllDataApi = async () => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.GET,
      `api/v1/users/profile`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserChangeUserNameAPI = async (formData) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/updateUserName`,
      formData
    );
    return response;
  } catch (error) {
    return error;
  }
};


export const UserChangeApi = async () => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.GET,
      `api/v1/users/generateUserName`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserChangeImg = async (formData) => {
  try {
    const response = await apiHandlerWithUserFromData(
      REQUEST_METHODS.POST,
      `api/v1/users/updateProfileImage`,
      formData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UserDelete = async () => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/deleteUser`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const TrackCategory = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/categorylog/create-categorylog`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const TrackGame = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/gamelog/create-gamelog`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const TrackSearch = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/searchlog/search-log`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const TrackLike = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/likelog/like-log`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const TrackDisLike = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/dislikelog/dislike-log`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const TrackFullScreen = async (body) => {
  try {
    const response = await apiHandlerWithUserFormData(
      REQUEST_METHODS.POST,
      `api/v1/users/fullscreenlog/fullscreen-log`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

