import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import {
  getSingleCategoryApi,
  updateCategoryApi,
  deleteCategoryImageApi,
} from "../../../APIs/AdminAll_api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../../Configs/Routes";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("false");
  const [categoryId, setCategoryId] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [Description, setDescription] = useState('');


  const getSingleCategory = async () => {
    setGetDataLoading(true);
    const data = await getSingleCategoryApi(id);
    if (data.status === 200) {
      setGetDataLoading(false);
      setName(data.data.data?.name);
      setStatus(data.data.data.status);
      setCategoryId(data.data.data._id);
      setImage(data.data.data.image);
      setDescription(data.data.data.description)
    }
  };

  useEffect(() => {
    getSingleCategory();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;

    if (re1.test(name)) {
      setLoading(false);
      toast.error("Please enter valid category name");
    } else if (name.trim() === "" || !re.test(name)) {
      setLoading(false);
      toast.error("Please enter valid category name");
    } else if (image === "") {
      setLoading(false);
      toast.error("Please enter image");
    } else {
      const passData = new FormData();
      passData.append("name", name);
      passData.append("status", status);
      passData.append("image", image);
      passData.append("description", Description)
      setLoading(true);

      const data = await updateCategoryApi(categoryId, passData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.CATEGORY.LIST);
        toast.success("Category Updated Successfully");
      }
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  const handleDelete = async (type) => {
    try {
      const data = await deleteCategoryImageApi(categoryId);
      if (data.status === 200) {
        setImage("");
        toast.success("Image Deleted Successfully");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Category</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Edit Category</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        {getDataLoading ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Col md={12} lg={10}>
            <Card className="box-shadow-0">
              <Card.Body>
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Name<label className="text-danger">*</label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      id="inputEmail"
                      value={name}
                      name="name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Upload Category Image<label className="text-danger">*</label>(18*18)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                  {image && !image?.name && (
                    <Form.Label className="get-lebal">
                      <spna className="me-2">{image}</spna>
                    </Form.Label>
                  )}
                  <Form.Group className="mt-3">
                    <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                      Status
                    </Form.Label>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Form.Check
                        type="radio"
                        label="Active"
                        name="status"
                        value="true"
                        checked={status}
                        onChange={handleStatusChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Inactive"
                        name="status"
                        value="false"
                        checked={!status}
                        onChange={handleStatusChange}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <ReactQuill theme="snow" value={Description} onChange={setDescription} />
                  </Form.Group>
                  <div>
                    <Button type="submit" className="editBtn">
                      {loading ? (
                        <div
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Submit
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={() => {
                        navigate(ROUTES.CATEGORY.LIST);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EditCategory;
