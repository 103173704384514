import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../Configs/Routes";
import { toast } from "react-toastify";
import Logins from "../../assets/Images/media/login.png";
const BASE_URL = process.env.REACT_APP_SERVER_URL;

export default function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const rememberCheckbox = document.getElementById("remember");

  const handleChange = (event) => {
    switch (event.target.name) {
      case "username":
        setUsername(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const loginApi = `${BASE_URL}api/v1/admins/login`;
    const data = {
      username: username,
      password: password,
    };
    axios({
      method: "POST",
      url: loginApi,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          localStorage.setItem("gameAdminToken", response.data.token);
          localStorage.setItem(
            "maidaan",
            JSON.stringify(response.data.data.admin)
          );
          toast.success(response.data.message);
          navigate(ROUTES.ADMIN_DASHBOARD);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  return (
    <>
      <div className="page" style={{ background: " #ecf0fa" }}>
        <div className="container-fluid">
          <Row className="row-contain">
            <Col lg={7} className="contain-col">
              <Row className="img-row mx-auto">
                <Col lg={12} className="img-col my-auto mx-auto d-md-flex">
                  <img
                    src={Logins}
                    className="login-img mx-auto my-auto wd-xl-80p wd-md-100p"
                  ></img>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="content-div">
              <div className="login py-2">
                <div className="container mx-auto my-auto">
                  <Row>
                    <Col md={12} className="mx-auto">
                      <div className="card-sigins">
                        <div className="main-signup-headers ">
                          <h2>Welcome to Aigamer </h2>
                          <h5 className="fw-semibold mb-4">
                            <b>Please sign in to Aigamer Dashboard.</b>
                          </h5>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                name="username"
                                onChange={handleChange}
                                className="pass-input"
                                placeholder="Enter your Email"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label className="mt-3">Password</Form.Label>
                              <Form.Control
                                name="password"
                                onChange={handleChange}
                                className="pass-input"
                                placeholder="Enter your Password"
                                type={passwordVisible ? "text" : "password"}
                              ></Form.Control>
                              <div className="d-flex">
                                <Form.Check
                                  onClick={handlePasswordVisibility}
                                  className="mt-2"
                                ></Form.Check>
                                <span
                                  className="mt-2"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Show Password
                                </span>
                              </div>
                            </Form.Group>
                            <Button className="signin-btn mt-3" type="submit">
                              {loading ? (
                                <div
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                " Sign In"
                              )}
                            </Button>
                          </Form>
                          <div className="main-signin-footer mt-3">
                            <p className="mb-2">
                              <Link
                                to={ROUTES.ADMIN_FORGET_PASSWORD}
                                className="createAccount"
                              >
                                Forgot password?{" "}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
