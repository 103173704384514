import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { OtplessApi } from '../../../APIs/UserAll_api';
import ROUTES from '../../../Configs/Routes';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

function Test() {
	const navigate = useNavigate()
	const [loading, setLoading] = useState()

	useEffect(() => {
		const script = document.createElement("script")
		script.src = "https://otpless.com/auth.js";
		script.id = "otplessIdScript";
		document.body.appendChild(script)
		window.otpless = (otplessUser) => {
			// alert(JSON.stringify(otplessUser));
			signUp(otplessUser)

		};

		return () => {
			const divid = document.getElementById("otplessIdScript");
			document.body.removeChild(divid);
		};
	}, []);

	const signUp = async (otplessUser) => {
		const data = {
			token: otplessUser.token,
		};

		setLoading(true)
		const respData = await OtplessApi(data);
		if (respData.status === 200) {
			localStorage.setItem("gameUserToken", respData.data?.token);
			setLoading(false)
			navigate(ROUTES.USER)
		} else if (respData.response.status === 400) {
			toast.error(respData.response.data.message)
		}
		else {
			setLoading(false)
		}
	}

	return (
		<div>
			<div id="otpless-login-page"></div>
			<div className='text-center'>
				<Button className='mt-4' onClick={() => { navigate(ROUTES.USER) }}>Back</Button>
			</div>
		</div>

	)
}

export default Test