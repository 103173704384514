import React, { useState, useEffect, useContext } from "react";
import { TrackCategory, getAllcategoryApi } from "../../APIs/UserAll_api";
import { NavLink, useParams } from "react-router-dom";
import MainContext from "../Context/MainContext";

function Sidebar({ sidebar, setSidebar }) {
  const { catId } = useParams();
  const [category, setCategory] = useState([]);
  const data = useContext(MainContext);

  const getAllCategory = async () => {
    try {
      const res = await getAllcategoryApi();
      if (res.status === 200) {
        setCategory(res.data.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const checkWindowSize = async (name, des) => {
    if (window.innerWidth < 1024) {
      setSidebar(false);
    }

    data.setCategoryName(name)
    data.setCategoryDescription(des)
  };

  const handleCategoryLog = async (id) => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        category_id: id
      }
      await TrackCategory(body)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  const handleClose = () => {
    setSidebar(false);
  };

  return (
    <div className={`sidebar-container ${sidebar ? "navbar_mobile_active" : "sidebar-container1"}`}>
      <div className={`nav-main ${sidebar ? undefined : "nav_small"}`}>
        <div className="close-btn">
          <i class="fa-solid fa-xmark" onClick={handleClose}></i>
        </div>
        <ul className="menu-container">
          {category.map((item, index) => {
            data.setCategoryName(item.name)
            const decodedParam = decodeURIComponent(item.name);
            const mergedParam = decodedParam.split(' ').join('').toLowerCase();

            return (
              <li className="li_navlink" key={index} onClick={() => handleCategoryLog(item?._id)}>
                <NavLink
                  to={`/play-category/${mergedParam}`}
                  onClick={() => checkWindowSize(item.name, item?.description)}
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  key={item._id}
                  state={{ category_id: item._id }}
                >
                  {item.image ? (
                    <img
                      src={item.image}
                      alt=""
                      className="user-category-image"
                    />
                  ) : (
                    <i class="fa-solid fa-puzzle-piece"></i>
                  )}
                  <span className="description">{item.name}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div >
  );
}

export default Sidebar;
