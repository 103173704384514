import React from 'react'
import { Modal } from "react-bootstrap";
import { Button, Form } from 'react-bootstrap';
import { deteleLinkApi } from "../../../APIs/AdminAll_api";
import { toast } from 'react-toastify';

const DeleteGame = ({ setDelete, socialId, getAllSocial }) => {

  const handleDelete = async () => {
    const data = await deteleLinkApi(socialId);
    if (data.status === 200) {
      setDelete(false)
      getAllSocial()
      toast.success("Game Deleted Successfully")
    }
  }

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title><h5 className="confirmTitle">Confirmation Alert!</h5></Modal.Title>
      </Modal.Header>
      <Form className='adminForm' onSubmit={handleDelete}>
        <Modal.Body>
          <p className='mb-2'><span>Are you sure to remove this Link?</span></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='modalNo' onClick={() => { setDelete(false) }}>No</Button>
          <Button variant="primary" className='modalYes' onClick={() => handleDelete()}>Yes</Button>
        </Modal.Footer>
      </Form>
    </div>
  )
}

export default DeleteGame