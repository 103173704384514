import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import {
  updateSocialApi,
  getSingleLinkApi
} from "../../../APIs/AdminAll_api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../../Configs/Routes";

const EditGame = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);

  const getSingleSocial = async () => {
    setGetDataLoading(true);
    const data = await getSingleLinkApi(id);
    if (data.status === 200) {
      setGetDataLoading(false);
      setName(data.data.data.name);
      setLink(data.data.data.url);
    } else {
      setGetDataLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("url", link);

    const data = await updateSocialApi(id, formData);
    if (data.status === 200) {
      setLoading(false);
      navigate(ROUTES.SOCIAL.LIST);
      toast.success("Game Updated Successfully");
    } else if (data.response.data.status === 422) {
      setLoading(false);
      toast.error("Name already exist");
    }
    else {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };


  useEffect(() => {
    getSingleSocial();
  }, []);

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Social Links</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Edit link</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        {getDataLoading ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Col lg={6}>
            <Card className="box-shadow-0">
              <Card.Body>
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Name<label className="text-danger">*</label>
                    </Form.Label>
                    <Form.Select
                      required
                      className="filter-card2"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      value={name}
                    >
                      <option value="" disabled selected>
                        Select Name
                      </option>
                      <option value="instagram">Instagram</option>
                      <option value="youtube">You Tube</option>
                      <option value="linkdin">Linkdin</option>
                      <option value="twitter">Twitter</option>
                      <option value="facebook">Facebook</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link"
                      id="inputEmail"
                      name="link"
                      value={link}
                      onChange={(e) => {
                        setLink(e.target.value);
                      }}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <div>
                    <Button type="submit" className="editBtn">
                      {loading ? (
                        <div
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Submit
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={() => {
                        navigate(ROUTES.SOCIAL.LIST);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EditGame;
