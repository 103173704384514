import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Col, Row, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import {
  getAllGameApi,
  getAllGameApiWithSearch,
  zipUpload,
} from "../../../APIs/AdminAll_api";
import Game1 from "../../../assets/Images/User/Games/game1.png";
import CustomModal from "../../../Configs/CustomModal/CustomModal";
import DeleteCategory from "./DeleteGame";
import Pagination from "../Pagination";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import ZipUpload from "./UploadZipModal"

const Index = () => {
  const navigate = useNavigate();
  const [allGame, setAllGame] = useState([]);
  const [Delete, setDelete] = useState(false);
  const [gameId, setGameId] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [totalRecord, setTotalRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [uploadZip, setUploadZip] = useState("");
  const [show, setShow] = useState(false);
  const [zipName, setZipName] = useState("")

  const handleAddGame = () => {
    navigate(ROUTES.GAME.ADD);
  };

  const getAllGame = async () => {
    setLoading(true);
    const data = await getAllGameApi(currentPage);
    if (data.status === 200) {
      setLoading(false);
      setTotalRecord(data?.data.total_record);
      setAllGame(data.data.data);
    }
  };

  useEffect(() => {
    if (!search) {
      getAllGame();
    }
  }, [currentPage, search]);

  const handleDelete = (index) => {
    setDelete(true);
    setGameId(index);
  };

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / 10;
      setTotalPage(page);
    }
  }, [totalRecord]);


  const searchApi = async () => {
    setLoading(true);
    try {
      const res = await getAllGameApiWithSearch(search, currentPage);
      if (res.status === 200) {
        setLoading(false);
        setTotalRecord(res?.data.total_record);
        setAllGame(res.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      searchApi();
    }
  }, [currentPage])

  const handleSearch = (e) => {
    e.preventDefault();
    if (search) {
      searchApi();
    }
  };

  const handleShow = (id, zip) => { setShow(true); setGameId(id); setZipName(zip) };

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Game</h4>
          </div>
        </div>
        <div className="d-flex my-xl-auto right-content align-items-center">
          <div className="dashPageSearch me-2">
            <Form className="d-flex flex-wrap" onSubmit={handleSearch}>
              <Form.Group controlId="userSearch">
                <Form.Control
                  type="search"
                  required
                  name="search"
                  placeholder="Search Game..."
                  value={search}
                  onChange={(e) => { setSearch(e.target?.value) }}
                />
              </Form.Group>
              <Button type="submit" className="search-btn">
                <i className="fa-solid fa-magnifying-glass"></i>
              </Button>
            </Form>
          </div>
          <div className="pe-1 mb-xl-0 ms-3">
            <Button
              type="button"
              className="btn add-btn"
              onClick={handleAddGame}
            >
              <i className="fa-regular fa-plus m-1"></i>
              Add Game
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive border-top userlist-table">
                <Table
                  responsive
                  className="table card-table table-hover table-vcenter text-nowrap mb-3"
                >
                  <thead className="mb-3">
                    <tr className="mb-3 head-color text-center">
                      <th>Name</th>
                      <th>Category</th>
                      <th>Image</th>
                      <th>ZIP Upload</th>
                      <th>Uploaded</th>
                      <th>Asset Status</th>
                      <th>Status</th>
                      <th>like Count</th>
                      <th>disLike Count</th>
                      <th>
                        <span>Action</span>
                      </th>
                      <th>Redirect To Game</th>
                    </tr>
                  </thead>
                  {!loading &&
                    allGame.length > 0 &&
                    allGame.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr className="text-center">
                            <td>{item.name}</td>
                            <td>{item?.category_id?.name}</td>
                            <td>
                              {item.image && <img
                                src={item.image}
                                alt="game"
                                className="game-image"
                              />}
                            </td>
                            <td><Button onClick={() => handleShow(item._id, item.webgl_zip)} disabled={item.is_asset_upload === 1}>
                              Upload zip
                            </Button></td>
                            <td>{item.is_zip_upload === 0 ? <span className="pending-badge">Pending</span> : item.is_zip_upload === 1 ? <span className="uplaoding-badge">Uploading</span> : item.is_zip_upload === 2 ? <span className="completed-badge">Completed</span> : "-"}</td>
                            <td>{item.is_asset_upload === 0 ? <span className="pending-badge">Pending</span> : item.is_asset_upload === 1 ? <span className="uplaoding-badge">Uploading</span> : item.is_asset_upload === 2 ? <span className="completed-badge">Completed</span> : "-"}</td>
                            <td>
                              {item.status === true ? "Active" : "Inactive"}
                            </td>
                            <td>
                              {item.likeCount}
                            </td>
                            <td>
                              {item.disLikeCount}
                            </td>
                            <td>
                              <Link
                                class="btn btn-sm btn-info btn-b"
                                to={`${ROUTES.GAME.UPDATE}/${item._id}`}
                              >
                                <i class="las la-pen"></i>
                              </Link>
                              <Link
                                class="btn btn-sm btn-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i class="las la-trash"></i>
                              </Link>
                            </td>
                            <td>
                              {item.status && <Button className="btn btn-sm btn-info btn-b" onClick={() => navigate(ROUTES.PLAY_GAME.LIST + "/" + item._id)}>
                                Redirect
                              </Button>}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
                {loading ? (
                  <div className="text-center">
                    <div
                      class="spinner-border m-2"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CustomModal
        display={Delete}
        handleClose={() => setDelete(false)}
        size="md"
        className="userModal"
      >
        <DeleteCategory
          setDelete={setDelete}
          gameId={gameId}
          getAllGame={getAllGame}
        />
      </CustomModal>

      <CustomModal
        display={show}
        handleClose={() => setShow(false)}
        size="md"
        className="userModal"
        backdrop="static"
      >
        <ZipUpload
          setShow={setShow}
          gameId={gameId}
          getAllGame={getAllGame}
          zipName={zipName}
        />
      </CustomModal>
      {
        totalPage >= 1 && (
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )
      }
    </div >
  );
};

export default Index;
