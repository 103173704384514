import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import {
  getSingleBannerApi,
  updateBannerApi,
  deteleBannerImageApi,
} from "../../../APIs/AdminAll_api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "../../../Configs/Routes";

const EditGame = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setname] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState(false);
  const [video_file, setVideo_File] = useState("");
  const [loading, setLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [video_fileLoading, setVideo_fileLoading] = useState(false);
  const [image_fileLoading, setImage_fileLoading] = useState(false);

  const getSingleBanner = async () => {
    setGetDataLoading(true);
    const data = await getSingleBannerApi(id);
    if (data.status === 200) {
      setGetDataLoading(false);
      setUrl(data.data.data.url);
      setname(data.data.data.name);
      setImage(data.data.data.image);
      setStatus(data.data.data.status);
      setVideo_File(data.data.data.video_file);
    } else {
      setGetDataLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const re = /^[a-zA-Z0-9 ]+$/;
    const re1 = /^[0-9 ]+$/;
    const re2 = /^[ ]+$/;
    const imageExt = image?.name?.split(".").pop();
    const videoExt = video_file?.name?.split(".").pop();

    if (re1.test(name)) {
      setLoading(false);
      toast.error("Please enter valid game name");
    } else if (re2.test(url)) {
      setLoading(false);
      toast.error("Please enter valid url");
    } else if (name.trim() === "" || !re.test(name)) {
      setLoading(false);
      toast.error("Please enter valid game name");
    } else if (
      image?.name &&
      imageExt !== "jpg" &&
      imageExt !== "jpeg" &&
      imageExt !== "png" &&
      imageExt !== "svg"
    ) {
      setLoading(false);
      toast.error("Please upload valid image");
    } else if (
      video_file?.name &&
      videoExt !== "mp4" &&
      videoExt !== "mkv" &&
      videoExt !== "avi" &&
      videoExt !== "mov" &&
      videoExt !== "wmv"
    ) {
      setLoading(false);
      toast.error("Please upload valid video file");
    } else if (image === null && video_file === null) {
      setLoading(false);
      toast.error("Please upload image or video");
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("status", status);
      formData.append("url", url);
      if (image?.name) {
        formData.append("image", image);
      }
      if (video_file?.name) {
        formData.append("video_file", video_file);
      }
      const data = await updateBannerApi(id, formData);
      if (data.status === 200) {
        setLoading(false);
        navigate(ROUTES.BANNER.LIST);
        toast.success("Banner Updated Successfully");
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value === "true");
  };

  useEffect(() => {
    getSingleBanner();
  }, []);

  const handleDelete = async (type) => {
    if (type === "video") {
      setVideo_fileLoading(true);
    }

    if (type === "image") {
      setImage_fileLoading(true);
    }
    const data = await deteleBannerImageApi(id, type);
    if (data.status === 200) {
      toast.success("Image Deleted Successfully");
      getSingleBanner();
      setVideo_fileLoading(false);
      setImage_fileLoading(false);
    } else {
      setVideo_fileLoading(false);
      setImage_fileLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="container-fluid">
      <div className="dashboard-header">
        <div className="my-auto">
          <div className="d-flex">
            <h4 className="content-title mb-0 my-auto">Banner</h4>
            <span className="text-muted1 mt-1 ms-2 mb-0">/ Edit Banner</span>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        {getDataLoading ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Col xs={10}>
            <Card className="box-shadow-0">
              <Card.Body>
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Name<label className="text-danger">*</label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      id="inputEmail"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Url</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Url"
                      id="inputEmail"
                      name="Url"
                      value={url}
                      onChange={(e) => {
                        setUrl(e.target.value);
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Game Image</Form.Label> (390*200)
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                  {image && !image?.name && (
                    <Form.Label className="get-lebal">
                      <span>{image}</span>
                      {image_fileLoading ? (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <i
                          class="fa-solid fa-circle-xmark ms-2"
                          onClick={() => {
                            handleDelete("image");
                          }}
                        ></i>
                      )}
                    </Form.Label>
                  )}
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Video</Form.Label> (390*200)
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setVideo_File(e.target.files[0]);
                      }}
                    />
                  </Form.Group>
                  {video_file && !video_file?.name && (
                    <Form.Label className="get-lebal">
                      {video_file}
                      {video_fileLoading ? (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <i
                          class="fa-solid fa-circle-xmark ms-2"
                          onClick={() => {
                            handleDelete("video");
                          }}
                        ></i>
                      )}
                    </Form.Label>
                  )}
                  <Form.Group className="mt-3">
                    <Form.Label style={{ color: "#031b4e", fontSize: "14px" }}>
                      Status
                    </Form.Label>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Form.Check
                        type="radio"
                        label="Active"
                        name="status"
                        value="true"
                        checked={status}
                        onChange={handleStatusChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Inactive"
                        name="status"
                        value="false"
                        checked={!status}
                        onChange={handleStatusChange}
                      />
                    </div>
                  </Form.Group>
                  <div>
                    <Button type="submit" className="editBtn">
                      {loading ? (
                        <div
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Submit
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={() => {
                        navigate(ROUTES.BANNER.LIST);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EditGame;
