import React from "react";

export const DisclaimerPolicy = () => {
  return (
    <div className="aboutDiv">
      <div className="my-5">
        <h2 className="aboutMainTitle text-uppercase">Disclaimer Policy</h2>
        <p className="aboutMainP">Effective Date: 5th May, 2023</p>
      </div>

      <div>
        <ul className="privacy-main">
          <li>Introduction</li>
          <p>
            The information provided on our web platform is for general
            informational purposes only. The use of our platform constitutes
            your acceptance of this Disclaimer Policy.
          </p>
          <li className="mt-4">No Warranties</li>
          <p>
            a) The information on our platform is provided on an "as is" and "as
            available" basis. We make no representations or warranties of any
            kind, express or implied, regarding the accuracy, reliability, or
            completeness of the information.
            <br /> b) We do not warrant that our platform will be error-free,
            uninterrupted, or free of viruses or other harmful components.
          </p>
          <li className="mt-4">Limitations and Exclusions of Liability </li>
          <p>
            a) We shall not be liable for any direct, indirect, incidental,
            consequential, or exemplary damages arising out of or in connection
            with your use of our platform. <br />
            b) In no event shall our total liability exceed the amount paid by
            you, if any, for accessing our platform.
          </p>
          <li className="mt-4"> Accuracy of Information </li>
          <p>
            While we strive to provide accurate and up-to-date information on
            our platform, we make no representations or warranties of any kind,
            express or implied, about the completeness, accuracy, reliability,
            suitability, or availability of the information.
          </p>
          <li className="mt-4">Third-Party Services and Content </li>
          <p>
            Our platform may feature third-party services, advertisements, or
            content. We do not endorse or assume any responsibility for the
            accuracy, reliability, or legality of such services or content. Your
            interactions with third-party services or content are solely between
            you and the respective third parties.
          </p>
          <li className="mt-4"> Exceptions </li>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of liability for certain damages. In
            such cases, our liability shall be limited to the maximum extent
            permitted by law.
          </p>
          <li className="mt-4">Changes and Revisions </li>
          <p>
            We reserve the right to update or revise this Disclaimer Policy at
            any time. Updated policies will be posted on our platform.
          </p>
          <li className="mt-4"> No Legal Advice </li>
          <p>
            The information provided on our platform is for general
            informational purposes only and should not be considered legal
            advice. You should consult with a qualified legal professional for
            specific legal advice pertaining to your individual circumstances.
          </p>
          <li className="mt-4"> Law and Jurisdiction </li>
          <p>
            This Disclaimer Policy shall be governed by and construed in
            accordance with the laws of [jurisdiction]. Any disputes arising
            from or related to this policy shall be subject to the exclusive
            jurisdiction of the courts in [jurisdiction].
          </p>
          <li className="mt-4">Contact Information </li>
          <p>
            For any questions or concerns regarding our Disclaimer Policy,
            please contact us at [contact information].
          </p>
        </ul>
      </div>
    </div>
  );
};
