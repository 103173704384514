import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES from '../Configs/Routes'
import { Form } from "reactstrap"
import AiGamer from "../assets/Images/User/AdminLogo.png";
import { useState } from 'react'
import OtpInput from 'react-otp-input'
import { useRef } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { UserEmailOtpVerify } from '../APIs/UserAll_api'
import MainContext from './Context/MainContext'
import { useContext } from 'react'

const UserOtp = () => {

  const context = useContext(MainContext);
  const navigate = useNavigate();

  const [isloading, setLoading] = useState(false)
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(60);

  const intervalRef = useRef();
  const decreaseNum = () => { setTimer((prev) => (prev > 0 ? prev - 1 : prev)) };
  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      countryCode: context.signupData?.countryCode,
      phone: context.signupData?.phone,
      otp: otp
    }
    const respData = await UserEmailOtpVerify(formData);
    if (respData?.status === 200) {
      toast.success(respData.data?.message)
      navigate(ROUTES.USER)
      localStorage.setItem("gameUserToken", respData.data?.token);
      setLoading(false)
    } else {
      toast.error(respData.response.data.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (Array.isArray(context.signupData) && context.signupData.length === 0) {
      navigate(ROUTES.AUTH_SIGNIN);
    }
  }, []);

  return (
    <>
      <div className='login-page'>
        <div className='main'>
          <div className="login-container">
            <div className="sign-in" style={{ padding: "32px", height: "60vh" }}>
              <div className='arrow'>
                <Link to={ROUTES.AUTH_LOGIN}>
                  <i class="fa-solid fa-arrow-left"></i>
                </Link>
                <img src={AiGamer} alt='' className="user-aigamer me-5 "></img>
              </div>

              <div className='text-dark mt-2 mb-3'>
                <p>Confirm with SMS code</p>
              </div>
              <span className='text-dark'>Sent to +{context.signupData?.phone}</span>

              <Form className='mt-4' onSubmit={handleSubmit}>

                <div className='email-otp '>
                  <div className='mt-1 form-input'>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      separator={<span className="form-box"></span>}
                      renderInput={(props) => <input {...props} />}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "45px",
                        height: "45px",
                        fontSize: "16px",
                        fontColor: "black",
                        marginRight: "5px",
                        backgroundColor: "white",
                        fontWeight: "400",
                        caretColor: "black",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none"
                      }}
                    />
                  </div>
                  <div className='resend mt-2'>
                    <p className='text-dark'>
                      Time Remaining {timer} sec{timer !== 1}
                      <Link className='resendOtpBtn mx-1 text-decoration-none' >Resend</Link>
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <Button type="submit" className="w-100 signupSubmitBtn">
                    {isloading ?
                      <div className='text-center'>
                        <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                      :
                      'Next'
                    }
                  </Button>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserOtp
