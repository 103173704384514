import React from "react";

export const TermsConditions = () => {
  return (
    <div className="aboutDiv">
      <div className="my-5">
        <h2 className="aboutMainTitle text-uppercase">Terms & Conditions</h2>
        <p className="aboutMainP">Effective Date: 5th May, 2023</p>
      </div>

      <div>
        <ul className="privacy-main">
          <li>Introduction</li>
          <p>
            Welcome to AiGamer.io! These Terms and Conditions ("the Terms")
            govern your use of our web platform, AiGamer.io. By accessing or
            using our platform, you agree to comply with these Terms. If you do
            not agree with any part of the Terms, you may not use the platform.
          </p>
          <li className="mt-4">Acceptance of Terms</li>
          <p>
            You acknowledge that you have read, understood, and agree to be
            bound by these Terms.
          </p>
          <li className="mt-4">Description of Service</li>
          <p>
            AiGamer.io provides a web platform for users to play various genres
            of games. The platform may also include additional features and
            services.
          </p>
          <li className="mt-4">Registration Information and Security </li>
          <p>
            To access certain features of the platform, you may need to create a
            user account. You agree to provide accurate and complete
            registration information. You are responsible for maintaining the
            confidentiality of your account credentials.
          </p>
          <li className="mt-4">User Obligations and Conduct </li>
          <p>
            a) Compliance: You agree to use the platform in compliance with
            applicable laws, regulations, and these Terms.
            <br /> b) User Conduct: You shall not engage in any activities that
            violate the rights of AiGamer.io or any third party or disrupt the
            proper functioning of the platform.
          </p>
          <li className="mt-4">Intellectual Property Rights</li>
          <p>
            a) Ownership: AiGamer.io retains all intellectual property rights
            related to the platform and its content, unless otherwise stated.
            <br /> b) Limited License: Subject to your compliance with these
            Terms, AiGamer.io grants you a limited, non-exclusive,
            non-transferable license to access and use the platform for
            personal, non-commercial purposes.
          </p>
          <li className="mt-4">Prohibited Activities </li>
          <p>
            You are strictly prohibited from engaging in any activities that may
            violate any applicable laws or regulations, infringe upon the rights
            of others, or harm the reputation or integrity of AiGamer.io. Such
            activities include, but are not limited to, hacking, data breaches,
            distribution of malware, and unauthorized access to our systems.
          </p>
          <li className="mt-4">Third-Party Links and Services</li>
          <p>
            Our platform may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of such
            third parties. We encourage you to review their privacy policies.
          </p>
          <li className="mt-4">User Communications </li>
          <p>
            By using our platform, you consent to receiving communications from
            AiGamer.io, including promotional and marketing materials. You can
            opt-out of receiving such communications by following the
            instructions provided in the communication or by contacting us
            directly.
          </p>
          <li className="mt-4">Termination of Service </li>
          <p>
            AiGamer.io may suspend or terminate your access to the platform if
            you violate these Terms or engage in unauthorized activities.
          </p>
          <li className="mt-4">Indemnification </li>
          <p>
            You agree to indemnify and hold AiGamer.io and its affiliates,
            directors, officers, employees, and agents harmless from any claims,
            damages, liabilities, or costs arising from your use of the platform
            or your violation of these Terms.
          </p>
          <li className="mt-4">Limitation of Liability </li>
          <p>
            AiGamer.io shall not be liable for any direct, indirect, incidental,
            consequential, or exemplary damages arising out of or in connection
            with your use of the platform.
          </p>
          <li className="mt-4">Dispute Resolution </li>
          <p>
            Any disputes arising from or related to these Terms or the use of
            the platform shall be resolved through mediation or, if necessary,
            binding arbitration.
          </p>
          <li className="mt-4">Modification of Terms </li>
          <p>
            AiGamer.io reserves the right to modify these Terms at any time.
            Updated Terms will be effective upon posting on the platform.
          </p>
          <li className="mt-4">Governing Law </li>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of [jurisdiction].
          </p>
        </ul>
      </div>
    </div>
  );
};
