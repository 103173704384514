import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Card, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetGameTrackDetails, GetGameTrackDetailsWithSearch, getAllGameApi } from "../../../APIs/AdminAll_api";
import Pagination from "../Pagination";

function GameTrack() {
	const { id } = useParams()
	const [allGame, setAllGame] = useState([]);
	const [totalPage, setTotalPage] = useState("");
	const [totalRecord, setTotalRecord] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [fieldWiseCount, setFieldWiseCount] = useState("")
	const [sortWise, setSortwise] = useState("desc")

	const getAllGame = async () => {
		setLoading(true);
		const data = await GetGameTrackDetails(id, sortWise, currentPage);
		if (data.status === 200) {
			setLoading(false);
			setTotalRecord(data?.data.totalrecord);
			setAllGame(data.data.data);
		}
	};

	useEffect(() => {
		if (!search && !fieldWiseCount && !sortWise) {
			getAllGame();
		}
	}, [currentPage, search, fieldWiseCount, sortWise]);


	useEffect(() => {
		if (totalRecord || totalRecord === 0) {
			var page = totalRecord / 10;
			setTotalPage(page);
		}
	}, [totalRecord]);

	const searchApi = async () => {
		setLoading(true);
		try {
			const res = await GetGameTrackDetailsWithSearch(id, search, fieldWiseCount, sortWise, currentPage);
			if (res.status === 200) {
				setLoading(false);
				setTotalRecord(res?.data.totalrecord);
				setAllGame(res.data.data);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (search || fieldWiseCount || sortWise) {
			searchApi();
		}
	}, [currentPage])

	const handleSearch = (e) => {
		e.preventDefault();
		if (search || fieldWiseCount || sortWise) {
			searchApi();
			setCurrentPage(1)
		}
	};

	const handleReset = () => {
		setSearch("");
		setFieldWiseCount("");
		setSortwise("")
	}

	return (
		<div className="container-fluid">
			<div className="dashboard-header flex-wrap">
				<div className="my-auto">
					<div className="d-flex">
						<h4 className="content-title mb-0 my-auto">Game</h4>
					</div>
				</div>
				<div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
					<div className="dashPageSearch me-2">
						<Form className="d-flex " onSubmit={handleSearch}>
							<Form.Select className="game-select-menu me-2" value={fieldWiseCount} onChange={(e) => { setFieldWiseCount(e.target.value) }}>
								<option>Select Any</option>
								<option value="game">Game Count Wise</option>
								<option value="like">Like Count Wise</option>
								<option value="dislike">Dislike Count Wise</option>
								<option value="fullscreen">Fullscreen Count Wise</option>
								<option value="search">Search Count Wise</option>
							</Form.Select>
							<Form.Select className="game-select-menu me-2" value={sortWise} onChange={(e) => { setSortwise(e.target.value) }}>
								<option>Select Any</option>
								<option value="desc">Descending</option>
								<option value="asc">Ascending</option>
							</Form.Select>
							<Form.Group controlId="userSearch" >
								<Form.Control
									type="search"
									name="search"
									placeholder="Search Game..."
									value={search}
									onChange={(e) => { setSearch(e.target?.value) }}
								/>
							</Form.Group>
							<Button type="submit" className="search-btn">
								<i className="fa-solid fa-magnifying-glass"></i>
							</Button>
							<Button onClick={handleReset} className="search-btn">
								reset
							</Button>
						</Form>
					</div>
				</div>
			</div>
			<Row className="row-sm">
				<Col md={12}>
					<Card>
						<Card.Body>
							<div className="table-responsive border-top userlist-table">
								<Table
									responsive
									className="table card-table table-hover table-vcenter text-nowrap mb-3"
								>
									<thead className="mb-3">
										<tr className="mb-3 head-color text-center">
											<th>Game Image</th>
											<th>Name</th>
											<th>Play Count</th>
											<th>Like Count</th>
											<th>Dislike Count</th>
											<th>Search Count</th>
											<th>Full Screen Count</th>
											<th>Status</th>
										</tr>
									</thead>
									{!loading &&
										allGame.length > 0 &&
										allGame.map((item, index) => {
											return (
												<tbody key={index}>
													<tr className="text-center">
														<td>
															{item.image && <img
																src={item.image}
																alt="game"
																className="game-image"
															/>}
														</td>
														<td>{item.name}</td>
														<td>{item?.gamecount}</td>
														<td>{item.likecount}</td>
														<td>{item.dislikecount}</td>
														<td>{item.searchcount}</td>
														<td>{item.fullscreencount}</td>
														<td>{item.status === true ? "Active" : "Inactive"}</td>
													</tr>
												</tbody>
											);
										})}
								</Table>
								{
									!loading && allGame.length === 0 && <div className="text-center">
										Data not found
									</div>
								}
								{loading && (
									<div className="text-center">
										<div
											class="spinner-border m-2"
											style={{ width: "3rem", height: "3rem" }}
											role="status"
										>
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								)}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			{
				totalPage >= 1 && (
					<Pagination
						totalPage={totalPage}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				)
			}
		</div >
	)
}

export default GameTrack